import {
  AiosType,
  append,
  createAiosAsset,
  createAiosPath,
  is,
  isFail,
  AiosStatus,
  isWait,
  AiosFileType,
  type AiosAsset,
} from 'aios';
import { createAiosNode, findAiosNode, type AiosNode } from '../../AiosNode';
import { getAiosNodeAction, setAiosNodeAction, addAiosNodeAction } from 'app/AiosNode/AiosNodeApi';
import { AppDataNode } from 'views';
import { setAiosNodeUiData } from 'app/AiosNode/AiosNodeUi';
import { doNew } from '../save/doSave';
import { doReload } from './loadNodeAssetFunctions';
import { loadNodeData } from './loadNodeData';
import { loadNodeDelete } from './loadNodeDelete';
import { loadNodeCreate } from './loadNodeCreate';
import { loadNodeUpdate } from './loadNodeUpdate';

export async function loadNodeAsset(node: AiosNode): Promise<void> {
  if (!is(node.pathEx.action)) {
    await loadNodeAssetDefault(node);
    return;
  }
  await loadNodeAssetAction(node);
}

async function loadNodeAssetDefault(node: AiosNode): Promise<void> {
  let functions;
  const asset = node.item as AiosAsset;
  if (asset?.file?.type === AiosFileType.Folder) {
    functions = { create: doNew, update: undefined, delete: undefined };
  } else {
    functions = { update: undefined, delete: undefined };
  }
  const { status, progress, loaded } = getAiosNodeAction(node);
  if (is(loaded)) {
    setAiosNodeUiData(node, { form: AppDataNode, functions });
    return;
  }
  if (isFail(status)) {
    setAiosNodeUiData(node, { form: AppDataNode, functions: { reload: doReload } });
    return;
  }
  if (!isWait(status) || !is(progress)) {
    setAiosNodeAction(node, { text: 'loading asset', status: AiosStatus.Processing, progress: 0.1 });
    setAiosNodeUiData(node, { form: AppDataNode, functions: { cancel: undefined } });
    return;
  }
  if (progress < 0.4) {
    if (!is(node.parent?.api.loaded)) {
      if (progress < 0.2) {
        setAiosNodeAction(node, { text: 'loading asset', status: AiosStatus.Processing, progress: 0.2 });
      } else {
        setAiosNodeAction(node, { text: 'loading asset', status: AiosStatus.Processing, progress: 0.3 });
      }
      return;
    }
    setAiosNodeAction(node, { text: 'loading asset', status: AiosStatus.Processing, progress: 0.4 });
    return;
  }
  if (progress < 0.5) {
    setAiosNodeAction(node, { text: 'loading asset', status: AiosStatus.Processing, progress: 0.5 });
    await loadAssetDefaultData(node);
  }
  setAiosNodeUiData(node, { form: AppDataNode, functions });
}

async function loadAssetDefaultData(node: AiosNode): Promise<void> {
  const success = await loadNodeData(node);
  const { action } = getAiosNodeAction(node);
  addAiosNodeAction(node, action);
  setAiosNodeAction(node);
  if (!is(success)) {
    return;
  }
  const asset = createAiosAsset(node.item);
  const { assets } = asset;
  // const { assets, versions, accesses } = asset;
  if (is(assets)) {
    for (let i = 0; i < assets.length; i++) {
      const child = assets[i];
      const childPath = createAiosPath({ path: child.path });
      let childNode = findAiosNode(node, childPath);
      if (!is(childNode)) {
        childNode = createAiosNode();
        node.nodes = append(node.nodes, childNode);
      }
      childNode.parent = node;
      childNode.pathEx = childPath;
      childNode.type = AiosType.Asset;
      childNode.item = child;
    }
  }
  // if (is(versions)) {
  //   const versionsPath = createAiosPath({ path: node.path.path, version: 'all' });
  //   let versionNode = findAiosNode(node, versionsPath);
  //   if (!is(versionNode)) {
  //     versionNode = createAiosNode({ path: versionsPath, parent: node });
  //     node.nodes = append(node.nodes, versionNode);
  //   }
  //   for (let i = 0; i < versions.length; i++) {
  //     const child = versions[i];
  //     const childPath = createAiosPath({ path: child.path });
  //     let childNode = findAiosNode(node, childPath);
  //     if (!is(childNode)) {
  //       childNode = createAiosNode();
  //       versionNode.nodes = append(versionNode.nodes, childNode);
  //     }      
  //     childNode.parent = versionNode;
  //     childNode.path = childPath;
  //     childNode.type = AiosType.Asset;
  //     childNode.item = child;

  //     createAiosNodeData(node);
  //     childNode.id = child.id as string;
  //     childNode.name = child.name as string;
  //     childNode.data = { type: AiosType.Asset, item: child };
  //   }
  // }
  // if (is(accesses)) {
  //   const accesesPath = createAiosPath({ path: node.path.path, mode: 'access' });
  //   let accessNode = node.find(accesesPath);
  //   if (!is(accessNode)) {
  //     accessNode = createAiosNode({ path: accesesPath, parent: node });
  //     node.nodes = append(node.nodes, accessNode);
  //   }
  //   for (let i = 0; i < accesses.length; i++) {
  //     const child = accesses[i];
  //     const childPath = createAiosPath({
  //       path: node.path?.path,
  //       mode: 'access',
  //       modeId: child.id,
  //     });
  //     let childNode = node.find(childPath);
  //     if (!is(childNode)) {
  //       childNode = createAiosNode();
  //       accessNode.nodes = append(accessNode.nodes, childNode);
  //     }
  //     childNode.parent = accessNode;
  //     childNode.id = child.id as string;
  //     childNode.path = childPath;
  //     childNode.name = 'access';
  //     childNode.data = { type: AiosType.Access, item: child };
  //   }
  // }
  // const useData = node.data;
  // if (!is(useData)) {
  //   return false;
  // }
  // useData.item = asset;
}

async function loadNodeAssetAction(node: AiosNode): Promise<void> {
  const { action } = node.pathEx;
  switch (action) {
    case 'create':
      await loadNodeCreate(node);
      break;
    case 'update':
      await loadNodeUpdate(node);
      break;
    case 'delete':
      await loadNodeDelete(node);
      break;
  }
}

// if (is(node.path.version)) {
//   if (node.path.version === 'all') {
//     loadAssetUi(node);
//     return;
//   }
//   if (node.path.version === 'new') {
//     if (isWait(getAiosNodeAction(node)?.output?.status)) {
//       loadAssetUi(node);
//       return;
//     }
//     const saving = node.nodes?.[0];
//     if (is(saving)) {
//       const { status: savingStatus } = getAiosNodeAction(saving);
//       if (isWait(savingStatus)) {
//         loadAssetUi(node);
//         return;
//       }
//       loadAssetUi(node);
//       return;
//     }
//     // const versions = node.parent;
//     // const asset = versions?.parent;
//     // const { asset: copy } = getAiosNodeData();
//     // const copy = asset?.data?.item as AiosAsset;
//     // if (is(copy)) {
//     //   const path = createAiosPath({ path: copy.path, version: '0.0.0' });
//     //   const asset = createAiosAsset({ ...copy, id: undefined, path: path.path, version: '0.0.0' })
//     //   node.data = {
//     //     type: AiosType.Asset,
//     //     item: asset
//     //   }
//     // }
//     loadAssetUi(node);
//     return;
//   }