import {
  AiosStatus,
  is,
  remove,
} from 'aios';
import { type AiosNode } from '../../AiosNode';
import { setAiosNodeAction } from 'app/AiosNode/AiosNodeApi';
import { AppState } from 'signals/AppState/AppState';

export async function doRemoveConfirm(node: AiosNode): Promise<void> {
  setAiosNodeAction(node, { text: 'deleting', status: AiosStatus.Processing, progress: 0.6 });
  // await removeNode(node);
  AppState.current.refresh();
  // setAiosNodeAction(node, { text: 'removing node', status: AiosStatus.Processing, progress: 0.6, waiting: true });
  // void delay(async () => {
  //   await removeNode(node);
  // });
  // 
}

export async function doRemoveFinish(node: AiosNode): Promise<void> {
  const objectNode = node.parent;
  const rootNode = objectNode?.parent;
  if (is(rootNode) && is(objectNode)) {
    rootNode.nodes = remove(rootNode.nodes, objectNode);
    AppState.current.goTo(rootNode.pathEx);
  }
}

// export async function doRemoveCancel(this: AiosNode): Promise<void> {
//   const { output } = getAiosNodeAction(this);
//   output.setFail({ status: AiosStatus.FailCancel });
//   if (is(this.api.client)) {
//     this.api.client.cancel();
//   }
// }

export async function doRemoveConfirmCancel(this: AiosNode): Promise<void> {
  // const { output } = getAiosNodeAction(this);
  // output.setFail({ status: AiosStatus.FailCancel });
  // if (is(this.api.client)) {
  //   this.api.client.cancel();
  //   // todo: chech otherplace
  // } else {
  //   if (is(this.parent)) {
  //     await this.parent.remove(this.path);
  //     setAiosNodeAction(this, {});
  //   }
  // }
}

// export function doRemove(node: AiosNode): void {
//   const { output } = getAiosNodeAction(node);
//   if (isFail(output?.status)) {
//     return;
//   }
//   if (isWait(output?.status)) {
//     return;
//   }
//   setAiosNodeAction(node, {
//     action: createAiosAction({
//       output: createAiosOutput({
//         text: `prparing to delete '${node.pathEx.path as string}'`,
//         status: AiosStatus.Processing,
//         progress: 0.1,
//       })
//     })
//   });
//   void delay(async () => {
//     await doRemovePrep(node);
//   });
// }

// async function doRemovePrep(node: AiosNode): Promise<void> {
//   const remove = node.parent;
//   if (!is(remove)) {
//     return;
//   }
//   // await appNodeLoadStage.call(remove);
//   const { output, status } = getAiosNodeAction(node);
//   if (isWait(status)) {
//     if (!is(output?.progress) || output?.progress === 0.4) {
//       output.progress = 0.2;
//     } else {
//       output.progress = output.progress + 0.1;
//     }
//     void delay(async () => {
//       await doRemovePrep(node);
//     });
//     AppState.current.refresh();
//     return;
//   }
//   setAiosNodeAction(node, {
//     action: createAiosAction({
//       output: createAiosOutput({
//         text: `confirming delete '${node.pathEx.path as string}'`,
//         status: AiosStatus.Processing,
//         progress: 0.5,
//       })
//     })
//   });
//   AppState.current.refresh();
// }


