import { isFail, is, AiosStatus } from 'aios';
import { type AiosNode } from 'app';
import { saveNodeData } from './saveNodeData';
import { getAiosNodeAction, setAiosNodeAction } from 'app/AiosNode/AiosNodeApi';

export async function saveNodeNext(node: AiosNode): Promise<boolean | undefined> {
  const { progress } = getAiosNodeAction(node);
  if (!is(progress) || progress < 0.1) {
    setAiosNodeAction(node, { text: 'saving data', status: AiosStatus.Processing, progress: 0.1 });
    return undefined;
  }
  if (progress < 0.2) {
    setAiosNodeAction(node, { text: 'saving data', status: AiosStatus.Processing, progress: 0.2 });
    const action = await saveNodeData(node);
    if (isFail(action.output?.status)) {
      return false;
    }
    setAiosNodeAction(node, { text: 'saving data', status: AiosStatus.Processing, progress: 0.9 });
    return undefined;
  }
  if (progress < 1.0) {
    setAiosNodeAction(node, { text: 'saving data', status: AiosStatus.Processing, progress: 1.0 });
    let nodes = node.nodes;
    if (is(nodes)) {
      nodes = nodes.filter((node) => !is(node.pathEx.action));
    }
    if (is(nodes)) {
      let anyUndefined = false;
      for (let i = 0; i < nodes.length; i++) {
        const child = nodes[i];
        const childSaved = await saveNodeNext(child);
        if (childSaved === false) {
          return false;
        }
        if (childSaved === undefined) {
          anyUndefined = true;
        }
      }
      if (is(anyUndefined)) {
        if (progress < 0.95) {
          setAiosNodeAction(node, { text: 'saving data', status: AiosStatus.Processing, progress: 0.95 });
        } else {
          setAiosNodeAction(node, { text: 'saving data', status: AiosStatus.Processing, progress: 0.9 });
        }
        return undefined;
      }
      return true;

      // todo: try {
      //   // eslint-disable-next-line @typescript-eslint/promise-function-async
      //   const promises = nodes.map((node) => saveNodeData(node));
      //   await Promise.all(promises);
      //   nodes.forEach(child => {
      //     const { status: childStatus } = getAiosNodeAction(child);
      //     if (isFail(childStatus)) {
      //       setAiosNodeAction(node, { status: AiosStatus.Fail });
      //       return false;
      //     }
      //     if (isWait(childStatus)) {
      //       setAiosNodeAction(node, { text: 'saving data', status: AiosStatus.Processing, progress: 0.9 });
      //       return undefined;
      //     }
      //   });
      // } catch (exception) {
      //   // output.setException(exception as AiosException);
      //   setAiosNodeAction(node, { status: AiosStatus.Fail });
      //   return false;
      // }
    }
  }
  return true;
}
