import {
  AiosStatus,
  is,
  isWait,
  delay,
  createAiosAction,
  type AiosData,
  AiosDataCommand,
  AiosType,
  createAiosDataInput,
  createAiosDataOutput,
  isOk,
} from 'aios';
import { type AiosNode } from '../../AiosNode';
import { AppState } from 'signals/AppState/AppState';
import { addAiosNodeAction, getAiosNodeAction, setAiosNodeAction } from 'app/AiosNode/AiosNodeApi';
import { createAiosClientApp } from 'utl';

export async function doRun(node: AiosNode, input?: string): Promise<void> {
  setAiosNodeAction(node, {
    action: createAiosAction({
      input: createAiosDataInput<AiosData>({
        type: AiosType.ActionCode,
        // item: { id: 'user', text: set(input, 'running') },
        item: { id: 'user', text: 'running', accountId: node.pathEx.account },
        command: AiosDataCommand.Run
      }),
      output: createAiosDataOutput<AiosData>({
        type: AiosType.ActionCode,
        item: { id: 'aios', text: '...' },
        status: AiosStatus.Processing,
        progress: 0.6,
      }),
    })
  });
  void delay(async () => {
    await doRunData(node);
  });
  AppState.current.refresh();
}

export async function doRunCancel(node: AiosNode): Promise<void> {
  const { action, output } = getAiosNodeAction(node);
  if (output?.progress === 0.6) {
    output.setFail({ status: AiosStatus.FailCancel, text: 'cancelled' });
    addAiosNodeAction(node, action);
  }
  if (is(node.api.client)) {
    node.api.client.cancel();
    node.api.client = undefined;
  }
  AppState.current.refresh();
}

async function doRunData(node: AiosNode): Promise<void> {
  const { input, output, status } = getAiosNodeAction(node);
  if (!is(output)) {
    return;
  }
  if (!isWait(status)) {
    return;
  }
  output.progress = 0.7;
  // const { assetPath } = getAiosNodeData(node);
  // const code: AiosAction = {
  //   path: assetPath,
  //   input: { item: { text: input } },
  // };
  // setAiosNodeAction(this, { input: `running ${this.path.path as string}(${code.input as string})`, output: 'Processing' });
  node.api.client = createAiosClientApp();
  // const runinput = createAiosDataInput<AiosAction>({
  //   type: node.pathEx.aiosType as AiosType,
  //   item: code,
  //   command: AiosDataCommand.Run,
  // }) as AiosDataInput<AiosData>;
  const runOutput = await node.api.client.send({ path: node.pathEx, input });
  node.api.client = undefined;
  if (isOk(runOutput.status)) {
    output.setOk();
    const item = output.item;
    const outputText = runOutput.item?.text;
    if (is(item) && is(outputText)) {
      item.text = outputText;
    }
  } else {
    output.setOk();
    // output.setFail();
    const item = output.item;
    const outputText = runOutput.text;
    if (is(item) && is(outputText)) {
      item.text = outputText;
    }
  }
  // node.api.action.output = runOutput;
  // output.override(runOutput as AiosOutput);
  // if (isFail(node.api.action?.output?.status)) {
  //   // this.addAction({ input: this.interface.action?.input, output: this.action?.output?.status });
  //   AppState.current.refresh();
  //   return;
  // }
  // const item = result?.item as AiosAction;
  // if (is(item)) {
  //   this.addAction({
  //     to: 'you',
  //     from: 'ai',
  //     input: `running ${this.path.path as string}(${code.input as string})`,
  //     output: item?.output as string
  //   });
  // }
  AppState.current.refresh();
}

// if (is(this.process.output)) {
//   this.result.override(this.process.output);
// }
// const result = this.process.output as AiosDataOutput<AiosAsset>;
// this.interface.showHistory({
//   from: 'aios',
//   name: 'openai',
//   text: `${result?.item?.file?.text as string}`,
//   result: this.result,
//   process: this.process,
// });

// 

