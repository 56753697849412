import AccountBoxIcon from '@mui/icons-material/AccountBox';
import AccountTreeIcon from '@mui/icons-material/AccountTree';
import AcUnitIcon from '@mui/icons-material/AcUnit';
import AddIcon from '@mui/icons-material/Add';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArticleIcon from '@mui/icons-material/Article';
import AudioFileIcon from '@mui/icons-material/AudioFile';
import AutoModeIcon from '@mui/icons-material/AutoMode';
import BoltIcon from '@mui/icons-material/Bolt';
import BugReportIcon from '@mui/icons-material/BugReport';
import CachedIcon from '@mui/icons-material/Cached';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import CloseIcon from '@mui/icons-material/Close';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import CodeIcon from '@mui/icons-material/Code';
import CodeOffIcon from '@mui/icons-material/CodeOff';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import CopyAllIcon from '@mui/icons-material/CopyAll';
import DarkModeIcon from '@mui/icons-material/DarkMode';
import DataObjectIcon from '@mui/icons-material/DataObject';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import DescriptionOutlinedIcon from '@mui/icons-material/DescriptionOutlined';
import DoneIcon from '@mui/icons-material/Done';
import EditIcon from '@mui/icons-material/Edit';
import EditNoteIcon from '@mui/icons-material/EditNote';
import EditNoteOutlinedIcon from '@mui/icons-material/EditNoteOutlined';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import FolderIcon from '@mui/icons-material/Folder';
import HelpIcon from '@mui/icons-material/Help';
import HourglassEmptyIcon from '@mui/icons-material/HourglassEmpty';
import ImageIcon from '@mui/icons-material/Image';
import InstallMobileIcon from '@mui/icons-material/InstallMobile';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import LightModeIcon from '@mui/icons-material/LightMode';
import MenuIcon from '@mui/icons-material/Menu';
import MenuBookOutlinedIcon from '@mui/icons-material/MenuBookOutlined';
import MobileOffIcon from '@mui/icons-material/MobileOff';
import NewspaperIcon from '@mui/icons-material/Newspaper';
import PersonIcon from '@mui/icons-material/Person';
import PowerIcon from '@mui/icons-material/Power';
import PsychologyAltIcon from '@mui/icons-material/PsychologyAlt';
import QueryStatsIcon from '@mui/icons-material/QueryStats';
import ReplayIcon from '@mui/icons-material/Replay';
import RemoveIcon from '@mui/icons-material/Remove';
import SendIcon from '@mui/icons-material/Send';
import SettingsIcon from '@mui/icons-material/Settings';
import SmartphoneIcon from '@mui/icons-material/Smartphone';
import ThumbUpIcon from '@mui/icons-material/ThumbUp';
import ThumbDownIcon from '@mui/icons-material/ThumbDown';
import TransformIcon from '@mui/icons-material/Transform';
import UnfoldLessIcon from '@mui/icons-material/UnfoldLess';
import UnfoldMoreIcon from '@mui/icons-material/UnfoldMore';
import VideoFileIcon from '@mui/icons-material/VideoFile';
import ViewInArIcon from '@mui/icons-material/ViewInAr';
import ViewQuiltIcon from '@mui/icons-material/ViewQuilt';
import VoiceChatIcon from '@mui/icons-material/VoiceChat';
import LoginIcon from '@mui/icons-material/Login';
import LogoutIcon from '@mui/icons-material/Logout';
import { is } from 'aios';

export function getIcon(name?: string): JSX.Element {
  const lower = is(name) ? name.toLowerCase() : '';
  switch (lower) {
    case 'access': return <LockOutlinedIcon />;
    case 'account': return <AccountTreeIcon />;
    case 'action': return <QueryStatsIcon />;
    case 'aios': return <AcUnitIcon />;
    case 'asset': return <ContentCopyIcon />;
    case 'back': return <ArrowBackIcon />;
    case 'cached': return <CachedIcon />;
    case 'cancel': return <CloseIcon />;
    case 'chat': return <VoiceChatIcon />;
    case 'clone': return <CopyAllIcon />;
    case 'close': return <CloseIcon />;
    case 'code': return <CodeIcon />;
    case 'collapse': return <UnfoldLessIcon />;
    case 'confirm': return <ThumbUpIcon />;
    case 'connect': return <PowerIcon />;
    case 'container': return <FolderIcon />;
    case 'content': return <MenuBookOutlinedIcon />;
    case 'create': return <AddIcon />;
    case 'dark': return <DarkModeIcon />;
    case 'data': return <EditNoteOutlinedIcon />;
    case 'debug': return <BugReportIcon />;
    case 'decline': return <ThumbDownIcon />;
    case 'delete': return <DeleteForeverIcon />;
    case 'desktop': return <ViewQuiltIcon />;
    case 'diff': return <CodeOffIcon />;
    case 'download': return <CloudDownloadIcon />;
    case 'expand': return <UnfoldMoreIcon />;
    case 'fail': return <CloseIcon />;
    case 'file': return <DescriptionOutlinedIcon />;
    case 'folder': return <FolderIcon />;
    case 'form': return <EditNoteIcon />;
    case 'generate': return <TransformIcon />;
    case 'help': return <HelpIcon />;
    case 'hide': return <ChevronRightIcon />;
    case 'image': return <ImageIcon />;
    case 'install': return <InstallMobileIcon />;
    case 'iterate': return <AutoModeIcon />;
    case 'json': return <DataObjectIcon />;
    case 'less': return <ExpandMoreIcon />;
    case 'left': return <KeyboardArrowLeftIcon />;    
    case 'light': return <LightModeIcon />;
    case 'load': return <ReplayIcon />;
    case 'login': return <LoginIcon />;
    case 'logout': return <LogoutIcon />;
    case 'markdown': return <NewspaperIcon />;
    case 'menu': return <MenuIcon />;
    case 'model': return <ViewInArIcon />;
    case 'more': return <KeyboardArrowRightIcon />;
    case 'movie': return <VideoFileIcon />;
    case 'narrow': return <SmartphoneIcon />;
    case 'ok': return <DoneIcon />;
    case 'okexists': return <DoneIcon />;
    case 'okcreated': return <DoneIcon />;
    case 'profile': return <AccountBoxIcon />;
    case 'reload': return <ReplayIcon />;
    case 'reset': return <ReplayIcon />;
    case 'remove': return <RemoveIcon />;
    case 'right': return <KeyboardArrowRightIcon />;    
    case 'run': return <BoltIcon />;
    case 'save': return <DoneIcon />;
    case 'send': return <SendIcon />;
    case 'settings': return <SettingsIcon />;
    case 'show': return <ExpandMoreIcon />;
    case 'sound': return <AudioFileIcon />;
    case 'text': return <ArticleIcon />;
    case 'tree': return <AccountTreeIcon />;
    case 'undo': return <ReplayIcon />;
    case 'uninstall': return <MobileOffIcon />;
    case 'unknown': return <PsychologyAltIcon />;
    case 'update': return <EditIcon />;
    case 'upload': return <CloudUploadIcon />;
    case 'user': return <PersonIcon />;
    case 'version': return <CopyAllIcon />;
    case 'wait': return <HourglassEmptyIcon />;
  }
  return <PsychologyAltIcon />;
  
}