import { type AiosStatus, is, isFail, isOk, isWait, set } from 'aios';
import { getClass } from 'signals';
import { Grid10Cell, type Grid10CellProps } from './Grid10Cell';
import { getIcon } from 'components/getIcon';

export interface Grid10CellIconProps extends Grid10CellProps {
  name?: string;
  icon?: string;
  status?: AiosStatus;
}

export function getGrid2IconProps(props: Grid10CellIconProps): { icon: JSX.Element, props: Grid10CellIconProps } {
  const copy = { ...props };  
  let color = '';
  if (is(copy.icon)) {
    copy.name = copy.icon;
  }
  if (is(copy.status)) {
    if (isOk(copy.status)) {
      copy.name = 'ok';
    } else {
      if (isWait(copy.status)) {
        copy.name = 'wait';
      } else {
        if (isFail(copy.status)) {
          copy.name = 'fail';
        }
      }
    }
  }
  const icon = getIcon(copy.name);
  const lower = is(copy.name) ? copy.name.toLowerCase() : '';
  switch (lower) {
    case 'confirm':
      color = 'green';
      break;
    case 'fail':
      color = 'red';
      break;
    case 'ok':
    case 'okexists':
    case 'okcreated':
      color = 'green'
      break;
    case 'reload':
      color = 'yellow'
      break;
    case 'save':
      color = 'green'
      break;
    case 'undo':
      color = 'blue';
      break;
    case 'wait':
      color = 'yellow';
      break;
  }
  return {
    icon,
    props: {
      ...props,
      color: set(props?.color, color)
    }
  };
}

export const Grid10CellIcon = (iprops: Grid10CellIconProps): JSX.Element => {
  const { icon, props } = getGrid2IconProps(iprops);
  let className = 'grid10-cell-icon';
  if (is(iprops?.spin)) {
    className = `${className} spin`;
  }
  return (
    <Grid10Cell {...props}>
      <div className={getClass(className)}>
        {icon}
      </div>
    </Grid10Cell>
  );
}