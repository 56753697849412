import {
    AiosStatus,
    is,
    AiosAssetSchema,
    append,
    isWait,
} from 'aios';
import { cloneAiosNode, type AiosNode } from '../../AiosNode';
import { getAiosNodeAction, setAiosNodeAction } from 'app/AiosNode/AiosNodeApi';
import { setAiosNodeUiData } from 'app/AiosNode/AiosNodeUi';
import { AppDataNode } from 'views';
import { doCancelSave, doSave, saveNode } from '../save';

export async function loadNodeUpdate(node: AiosNode): Promise<void> {
    const { progress, loaded } = getAiosNodeAction(node);
    if (!is(loaded)) {
        const parent = node.parent;
        if (!is(parent)) {
            return;
        }
        const clone = cloneAiosNode(parent);
        if (!is(node.nodes)) {
            node.nodes = append(node.nodes, clone)
        }
        setAiosNodeAction(node, { text: 'updating asset', status: AiosStatus.Ok, loaded: true });
        setAiosNodeUiData(node, { form: AppDataNode, schema: AiosAssetSchema, functions: { close: doCancelSave, save: doSave } });
        return;
    }
    if (!is(progress) || progress < 0.6) {
        setAiosNodeUiData(node, { form: AppDataNode, schema: AiosAssetSchema, functions: { close: doCancelSave, save: doSave } });
        return;
    }
    if (progress < 1.0) {
        await saveNode(node);
        const { status } = getAiosNodeAction(node);
        if (isWait(status)) {
            setAiosNodeUiData(node, { form: AppDataNode, schema: AiosAssetSchema, functions: { cancel: doCancelSave } });
            return;
        }
        setAiosNodeUiData(node, { form: AppDataNode, schema: AiosAssetSchema, functions: { close: doCancelSave, save: doSave } });
    }
}

