import {
  type AiosData,
  type AiosDataInput,
  type AiosDataOutput,
  type AiosAction,
  type AiosClient,
  type AiosException,
  AiosStatus,
  createAiosAction,
  createAiosOutput,
  is,
  append,
  AiosDataCommand,
  AiosType,
  createAiosAccount,
  createAiosAsset,
  createAiosDataInput,
  createAiosDataOutput,
} from 'aios';
import { type AiosNode } from './AiosNode';
import { createAiosClientApp } from 'utl';
import { getAiosNodeData } from './AiosNodeData';

export interface AiosNodeApi {
  client?: AiosClient;
  action?: AiosAction;
  actions?: AiosAction[];
  loaded?: boolean;
}

interface AiosNodeApiOptions {
  action: AiosAction;
  actions: AiosAction[];
  input: AiosDataInput<AiosData>;
  output: AiosDataOutput<AiosData>;
  status: AiosStatus;
  progress?: number;
  created?: boolean;
  loaded?: boolean;
  text?: string;
}

export function getAiosNodeAction(node: AiosNode): AiosNodeApiOptions {
  const action = node.api.action as AiosAction;
  const actions = node.api.actions as AiosAction[];
  const input = action?.input as AiosDataInput<AiosData>;
  const output = action?.output as AiosDataOutput<AiosData>;
  const status = output?.status;
  const progress = output?.progress as number;
  const loaded = node.api.loaded;  
  return { action, actions, input, output, status, progress, loaded };
}

export function setAiosNodeAction(node: AiosNode, options?: Partial<AiosNodeApiOptions>): AiosAction | undefined {
  if (!is(options)) {
    node.api.action = undefined;
    return;
  }
  const { input, status, progress, loaded, text } = options;
  let action = options.action;
  let output = options.output;
  if (!is(output)) {
    output = createAiosOutput({ text, status, progress })
  }
  if (!is(action)) {
    action = createAiosAction({ input, output })
  }
  if (loaded !== undefined) {
    node.api.loaded = loaded;
  }
  node.api.action = action;
  return action;
};

export function addAiosNodeAction(node: AiosNode, action?: AiosAction): void {
  if (is(action)) {
    node.api.actions = append(node.api.actions, action);
  }
};

export async function processNodeData(node: AiosNode, command: AiosDataCommand): Promise<AiosAction> {
  let text = '';
  switch (command) {
    case AiosDataCommand.Load:
      text = 'loading';
      break;
    case AiosDataCommand.Save:
      text = 'saving';
      break;
    case AiosDataCommand.Remove:
      text = 'removing';
      break;
  }
  const { type, item } = getAiosNodeData(node);
  const input = createAiosDataInput({ type, command });
  switch (type) {
    case AiosType.Account:
      text = `${text} account`;
      // if (command === AiosDataCommand.Save) {
        input.item = createAiosAccount({ ...item, assets: undefined });
      // }
      break;
    case AiosType.Asset:
      text = `${text} asset`;
      // if (command === AiosDataCommand.Save) {
        input.item = createAiosAsset({ ...item, assets: undefined });
      // }
      break;
  }
  const output = createAiosDataOutput({ text, status: AiosStatus.Processing });
  const action = createAiosAction({ input, output });  
  try {
    node.api.client = node.api.client = createAiosClientApp();
    const apiOutput = await node.api.client.send({ path: node.pathEx, input });
    output.override(apiOutput);
    output.text = text;
  } catch (exception) {
    output.setFail({ text, exception: exception as AiosException });
  } finally {
    node.api.client = undefined;
  }
  return action;
}