import { AiosStatus, createAiosPath, is, isFail, isWait, remove } from 'aios';
import { type AiosNode } from 'app/AiosNode';
import { addAiosNodeAction, getAiosNodeAction, setAiosNodeAction } from 'app/AiosNode/AiosNodeApi';
import { saveNodeNext } from './saveNodeNext';
import { AppState } from 'signals/AppState/AppState';

export async function saveNode(node: AiosNode): Promise<void> {
    const { status, progress } = getAiosNodeAction(node);
    if (isFail(status) || !isWait(status)) {
        return;
    }
    if (!is(progress) || progress < 1.0) {
        const newNode = node.nodes?.[0];
        const newPath = newNode?.item?.path as string;
        if (!is(newNode)) {
            setAiosNodeAction(node, { text: `saving ${newPath}`, status: AiosStatus.Fail, progress: undefined });
            return;
        }
        if (!is(progress) || progress < 0.7) {
            setAiosNodeAction(node, { text: `saving ${newPath}`, status: AiosStatus.Processing, progress: 0.7 });
            return;
        }
        if (progress < 0.9) {
            if (progress === 0.8) {
                return;
            }
            if (progress < 0.75) {
                setAiosNodeAction(node, { text: `saving ${newPath}`, status: AiosStatus.Processing, progress: 0.75 });
            } else {
                setAiosNodeAction(node, { text: `saving ${newPath}`, status: AiosStatus.Processing, progress: 0.7 });
            }
        }
        const saved = await saveNodeNext(newNode);
        if (saved === undefined) {
            return;
        }
        if (!is(saved)) {
            setAiosNodeAction(node, { text: `saving ${newPath}`, status: AiosStatus.Fail, progress: undefined });
            return;
        }
        setAiosNodeAction(newNode, { text: `saved ${newPath}`, status: AiosStatus.Ok });
        const { action } = getAiosNodeAction(newNode);
        addAiosNodeAction(newNode, action);
        setAiosNodeAction(newNode);

        if (node.pathEx?.action === 'create') {
            const actionNode = node.parent;
            const parent = actionNode?.parent;    
            if (is(parent) && is(actionNode) && is(parent.nodes)) {
                parent.nodes = remove(parent.nodes, actionNode);
            }
            const path = createAiosPath({ path: newNode?.pathEx.path, action: 'update' });
            AppState.current.goTo(path);
            return;
        }
    }
    setAiosNodeAction(node, { text: 'saving', status: AiosStatus.Ok, progress: undefined });
};