import { type AiosPath, is, isChildPath, getNextPath, createAiosPath, append, AiosType } from 'aios';
import { createAiosNode, findAiosNode } from 'app/AiosNode';
import { type AiosNode } from 'app/AiosNode/AiosNode';
import { loadNodeAsset } from './loadNodeAsset';
import { loadNodeAccount } from './loadNodeAccount';
import { loadNodeAios } from './loadNodeAios';

export async function loadNode(node: AiosNode, target?: AiosPath): Promise<AiosNode | undefined> {
  if (!is(target)) {
    target = node.pathEx;
  }
  const thisFull = node.pathEx?.full as string;
  const targetFull = target?.full as string;
  if (thisFull !== targetFull) {
    return await loadNodes(node, target);
  }
  return await loadType(node, target);
}

async function loadNodes(node: AiosNode, target: AiosPath): Promise<AiosNode | undefined> {
  if (is(node.nodes)) {
    const targetFull = target.full as string;
    for (let i = 0; i < node.nodes.length; i++) {
      const child = node.nodes[i];
      const childFull = child.pathEx?.full as string;
      if (isChildPath(child.pathEx, target)) {
        await loadType(child, target);
        if (childFull === 'aios' || childFull === targetFull) {
          return child;
        }
        return await loadNodes(child, target);
      }
    }
  }
  return await loadInit(node, target);
}

async function loadInit(node: AiosNode, target: AiosPath): Promise<AiosNode | undefined> {
  const targetFull = target.full as string;
  const nextPath = getNextPath(node.pathEx, target);
  const path = createAiosPath({ full: nextPath });
  let next = findAiosNode(node, path);
  if (!is(next)) {
    next = createAiosNode({
      parent: node,
      pathEx: path
    });
    node.nodes = append(node.nodes, next);
  }
  if (path.full !== targetFull) {
    await loadType(next, target);
    return await loadNodes(next, target);
  }
  return await loadType(next, target);
}

async function loadType(node: AiosNode, target: AiosPath): Promise<AiosNode | undefined> {
  if (node.type === AiosType.AiosNodeRoot) {
    return node;
  }
  if (node.type === AiosType.AiosNodeAios) {
    return await loadNodeAios(node, target);
  }
  switch (node.pathEx.aiosType) {
    case AiosType.Account:
      await loadNodeAccount(node);
      break;
    case AiosType.Asset:
      await loadNodeAsset(node);
      break;
    // case AiosType.Access:
    //   await appNodeLoadAccess(node, target);
    //   break;
    // case AiosType.Action:
    //   await appNodeLoadAction(node, target);
    //   break;
  }
  return node;
}
