import { Grid10Cell, Grid10CellButton, Grid10CellIcon, Grid10CellInput, Grid10Row } from 'components';
import { AiosFileType, is } from 'aios';
import { useState } from 'react';
import { type AppDataType, type AppDataProps } from 'views/AppData';
import { getAiosNodeData } from 'app/AiosNode/AiosNodeData';
import { doChat, doRun } from 'app/funcs';

export const AppActionFootInput: AppDataType = ({ node }: AppDataProps) => {
  const [input, setInput] = useState('');
  const [more, setMore] = useState('expand');
  const [mode, setMode] = useState('json');
  if (!is(node)) {
    return (<div>AppChatFootInput no node</div>);
  }
  const { assetFileType } = getAiosNodeData(node);
  const onMore = async (): Promise<void> => {
    if (more === 'expand') {
      setMore('collapse');
    } else {
      setMore('expand');
    }
  }
  const onMode = async (): Promise<void> => {
    if (mode === 'json') {
      setMode('text');
    } else {
      setMode('json');
    }
  }
  const onSend = async (): Promise<void> => {
    setInput('');
    await doChat(node, input);
  }
  const onRun = async (): Promise<void> => {
    await doRun(node, input)
  }
  const onInput = (input: string): void => {
    setInput(input);
  }
  if (more === 'expand') {
    return (
      <Grid10Row>
        <Grid10CellButton tip={more} icon={more} onClick={onMore} />
        <Grid10CellInput spanx={8} spany={2} value={input} onChange={onInput} />
        <Grid10CellButton tip='send' icon='send' onClick={onSend} />
        <Grid10Row>
          <Grid10Cell>&nbsp;</Grid10Cell>
          { assetFileType !== AiosFileType.Code ? (
            <Grid10Cell>&nbsp;</Grid10Cell>
          ) : (
            <Grid10CellButton tip='run' icon='run' onClick={onRun} />
          )}
        </Grid10Row>
      </Grid10Row>
    );
  }
  if (mode === 'json') {
    return (
      <>
        <Grid10Row>
          <Grid10CellButton tip={more} icon={more} onClick={onMore} />
          <Grid10CellInput spanx={8} spany={2} value={input} onChange={onInput} />
          <Grid10CellButton tip='send' icon='send' onClick={onSend} />
        </Grid10Row>
        <Grid10Row>
          <Grid10CellButton tip={mode} icon={mode} onClick={onMode} />
          <Grid10CellButton tip='run' icon='run' onClick={onRun} />
        </Grid10Row>
      </>
    );
  }
  return (
    <>
      <Grid10Row>
        <Grid10CellButton icon={more} onClick={onMore} />
        <Grid10Cell spanx={8} spany={2}>
          {/* <JsonEditor text={text} onChange={setText} /> */}
        </Grid10Cell>
        <Grid10CellButton icon='send' onClick={onSend} />
      </Grid10Row>
      <Grid10Row>
        <Grid10CellButton icon={mode} onClick={onMode} />
        <Grid10CellButton icon='run' onClick={onRun} />
      </Grid10Row>
    </>
  );
  return (
    <>
      <Grid10Row>
        <Grid10CellIcon icon="aios" />
        <Grid10CellInput spanx={8} value={input} spany={2} onChange={onInput} />
        {/* <Grid10Ex size={5} cols0={0} cols1={1} cols2={1}>
        {/* {mode === 'json' ? (
          <Grid10CellInputMulti options={options} value={input} onChange={onInput} />
        ) : (
          <Grid10Cell>
            <JsonEditor text={text} onChange={setText} />
          </Grid10Cell>
        {/* )}
        <Grid10Cell>
          
          {file?.type !== AiosFileType.Code || more === 'less' ? (<></>) : (
            
          )}
        </Grid10Cell>
      </Grid10Ex> */}
        <Grid10CellButton icon='send' onClick={onSend} />
      </Grid10Row>
      <Grid10Row>
        <Grid10CellButton icon={more} onClick={onMore} />
        {/* <Grid10CellButton icon={mode} onClick={onMode} /> */}
        <Grid10CellButton icon='run' onClick={onRun} />

        {/* <Grid10CellButton icon={more} onClick={onMore} /> */}
        {/* {more === 'less' ? (<></>) : (
        
      )} */}
      </Grid10Row>
    </>
  );
};