import { is } from '..';

export enum AiosFileType {
  None = '',
  Folder = 'Folder',
  Markdown = 'Markdown',
  Code = 'Code',
  Data = 'Data',
  Text = 'Text',
  Json = 'Json',
  Image = 'Image',
  Audio = 'Audio',
  Model = 'Model',
  Video = 'Video',
}

export const isFolder = (type?: AiosFileType): boolean => {
  if (is(type)) {
    switch (type) {
      case AiosFileType.Folder:
        return true;
    }
  }
  return false;
};

export const isFile = (type?: AiosFileType): boolean => {
  if (is(type)) {
    switch (type) {
      case AiosFileType.None:
      case AiosFileType.Folder:
        return false;
    }
    return true;
  }
  return false;
};

export const isText = (type?: AiosFileType): boolean => {
  if (is(type)) {
    switch (type) {
      case AiosFileType.Markdown:
      case AiosFileType.Code:
      case AiosFileType.Text:
      case AiosFileType.Json:
        return true;
    }
  }
  return false;
};
