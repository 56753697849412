import { is, isWait } from 'aios';
import { Grid10Row, Grid10Cell } from 'components';
import { AppDataCode } from './AppDataCode';
import { AppDataForm } from './AppDataForm';
import { AppState } from 'signals/AppState/AppState';
import { type AppDataProps, type AppDataType } from './AppDataType';
import { getAiosNodeData, setAiosNodeData } from 'app/AiosNode/AiosNodeData';
import { getAiosNodeAction } from 'app/AiosNode/AiosNodeApi';
import { AppDataMarkdown } from './AppDataMarkdown';

export const AppDataBody: AppDataType = ({ node, view, mode, expand, disabled }: AppDataProps) => {
  const { status } = getAiosNodeAction(node);
  if (isWait(status)) {
    disabled = true;
  }
  const { assetFileText, jsonText } = getAiosNodeData(node);
  let diffText: string | undefined;
  let diffJsonText: string | undefined;
  if (is(node.store)) {
    const { assetFileText: storedText, jsonText: storedJson } = getAiosNodeData(node.store);
    diffText = storedText;
    diffJsonText = storedJson;
  }
  const renderBody = (): JSX.Element => {
    if (view === 'data') {
      const onValue = (v?: string): void => {
        setAiosNodeData(node, { jsonText: v });
        AppState.current.refresh();
      };
      switch (mode) {
        case 'form':
          return (<AppDataForm node={node} disabled={disabled} />);
        case 'code':
          return (<AppDataCode node={node} type='json' diff={diffJsonText} mode={mode} value={jsonText as string} onValue={onValue} disabled={disabled} />);
        case 'diff':
          return (<AppDataCode node={node} type='json' diff={diffJsonText} mode={mode} value={jsonText as string} onValue={onValue} disabled={disabled} />);
      }
      return (<div>mode not supported</div>);
    }
    const onValue = (v?: string): void => {
      setAiosNodeData(node, { assetFileText: v });
      AppState.current.refresh();
    };
    switch (mode) {
      case 'code':
        return (<AppDataCode node={node} diff={diffText} mode={mode} value={assetFileText as string} onValue={onValue} disabled={disabled} />);
      case 'diff':
        return (<AppDataCode node={node} diff={diffText} mode={mode} value={assetFileText as string} onValue={onValue} disabled={disabled} />);
      case 'markdown':
        return (<AppDataMarkdown node={node} value={assetFileText as string} onValue={onValue} disabled={disabled} />);
    }
    return (<div>mode not supported</div>);
  }
  let spany = 10;
  if (expand === 'collapse') {
    spany = 10;
  }
  let border = '1px solid white';
  if (is(disabled)) {
    border = '1px solid grey';
  }
  return (
    <Grid10Row>
      <Grid10Cell spanx={10} spany={spany}>
        <div style={{ border, padding: '10px', height: '100%', overflowY: 'auto' }}>
          {renderBody()}
        </div>
      </Grid10Cell>
    </Grid10Row>
  );
}
