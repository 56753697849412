import {
  type AiosType,
  type AiosException,
  AiosStatus,
  AiosDataCommand,
  createAiosDataOutput,
  is,
  createAiosData,
  createAiosAction,
  createAiosDataInput,
  set,
  isOk,
} from 'aios';
import { type AiosNode } from 'app';
import { getAiosNodeAction, setAiosNodeAction } from 'app/AiosNode/AiosNodeApi';
import { getAiosNodeCache, setAiosNodeCache } from 'app/AiosNode/AiosNodeCache';
import { createAiosClientApp } from 'utl';

export async function loadNodeData(node: AiosNode): Promise<boolean> {
  const { loaded } = getAiosNodeAction(node);
  if (is(loaded)) {
    return true;
  }
  const { output } = getAiosNodeAction(node);
  const text = set(output?.text, 'loading');
  try {
    const apiInput = createAiosDataInput({
      type: node.type,
      item: node.item,
      command: AiosDataCommand.Load,
    });
    setAiosNodeAction(node, {
      action: createAiosAction(
        {
          input: apiInput,
          output: createAiosDataOutput({ status: AiosStatus.Processing })
        }
      )
    });

    if (!is(apiInput.type)) {
      apiInput.type = node.pathEx.aiosType as AiosType;
    }
    if (!is(apiInput.item)) {
      apiInput.item = createAiosData({ path: node.pathEx.full });
    }

    let apiOutput;
    if (!is(await getAiosNodeCache(node))){
      node.api.client = createAiosClientApp();
      apiOutput = await node.api.client.send({ path: node.pathEx, input: apiInput });
      node.api.client = undefined;
      if (isOk(apiOutput.status)){
        node.item = apiOutput.item;
        node.items = apiOutput.items;
        await setAiosNodeCache(node);
      }
    } else {
      apiOutput = createAiosDataOutput({
        text: 'loaded fome caches',
        item: node.item,
        items: node.items,
        status: AiosStatus.OkCache,
      });
    }
    setAiosNodeAction(node, {
      action: createAiosAction(
        {
          input: apiInput,
          output: apiOutput
        }
      )
    });
    apiOutput.text = text;
    if (apiOutput?.status === AiosStatus.FailCancel) {
      return false;
    }
    node.api.loaded = true;
  } catch (exception) {
    if (is(output)) {
      output.setFail({ text, exception: exception as AiosException });
    }
  }
  return true;
}
