import { useState } from 'react';
import { type AiosAction, AiosType, createAiosObject, is, isFail, isWait, set } from 'aios';
import { Grid10Row, Grid10Cell, Grid10CellButton, Grid10CellIcon, Grid10CellText, ProgressBar } from 'components';
import { useApp } from 'signals';
import { Editor } from '@monaco-editor/react';
import { formatStatus } from './format';

export interface AppActionItemProps {
  action: AiosAction;
  index?: string;
}

export const AppActionItem = ({ action, index = '' }: AppActionItemProps): JSX.Element => {
  const { settings } = useApp();
  const [more, setMore] = useState('more');
  const [mode, setMode] = useState('code');
  const [expand, setExpand] = useState('expand');
  const theme = !is(settings.dark) ? 'vs-dark' : undefined;
  const { input, output } = action;
  const doMore = async (): Promise<void> => {
    if (more === 'more') {
      setMore('less');
    } else {
      setMore('more');
    }
  };
  const doExpand = async (): Promise<void> => {
    switch (expand) {
      case 'expand':
        setExpand('collapse');
        break;
      case 'collapse':
        setExpand('expand');
        break;
    }
  };
  const renderHead = (): JSX.Element => {
    const space = (more === 'more') ? true : undefined;
    const renderOutput = (): JSX.Element => {
      if (output?.type === AiosType.ActionChat || output?.type === AiosType.ActionCode) {
        if (isWait(output?.status)) {
          return (
            <>
              <Grid10CellIcon space={space} icon='aios' spin />
              <Grid10CellText space={space} spanx={8}>
                <span> ... </span>
              </Grid10CellText>
            </>
          )
        }
        return (
          <>
            <Grid10CellIcon space={space} icon='aios' />
            <Grid10CellText space={space} spanx={8}>
              <span>{output?.item?.text}</span>
            </Grid10CellText>
          </>
        )
      }
      const icon = set(
        output?.item?.id
        , 'aios'
      );
      const text = set(
        output?.text,
        set(
          output?.item?.text,
          '')
      );
      if (isWait(output?.status)) {
        return (
          <>
            <Grid10CellIcon space={space} icon={icon} spin />
            <Grid10CellText space={space} spanx={8}>
              <span>{text}</span>
              <span> ... </span>
              <span data-testid={`result-${index}`}>{formatStatus(output?.status)}</span>
            </Grid10CellText>
          </>
        )
      }
      if (!isFail(output?.status)) {
        return (
          <>
            <Grid10CellIcon space={space} icon='ok' />
            <Grid10CellText space={space} spanx={8}>
              <span>{text}</span>
              <span> ... </span>
              <span data-testid={`result-${index}`}>{formatStatus(output?.status)}</span>
            </Grid10CellText>
          </>
        )
      }
      return (
        <>
          <Grid10CellIcon space={space} icon='fail' />
          <Grid10CellText space={space} spanx={8}>
            <span>{text}</span>
            <span> ... </span>
            <span data-testid={`result-${index}`}>{formatStatus(output?.status)}</span>
          </Grid10CellText>
        </>
      )
    }
    const inputText = set(input?.item?.text, '');
    return (
      <>
        {!is(inputText) ? (<></>) : (
          <Grid10Row>
            <Grid10Cell>&nbsp;</Grid10Cell>
            <Grid10CellText spanx={8} right>{inputText}</Grid10CellText>
            <Grid10CellIcon icon={input?.item?.id as string} />
          </Grid10Row>
        )}
        <Grid10Row>
          {renderOutput()}
          <Grid10CellButton icon={more} onClick={doMore} space={space} />
        </Grid10Row>
      </>
    );
  }
  const renderMore = (): JSX.Element => {
    if (more === 'more') {
      return (<></>);
    }
    let spany = 10;
    if (expand === 'expand') {
      spany = 4;
    }
    const actionObject = createAiosObject(action);
    const jsonObject = JSON.stringify(actionObject, undefined, 2);
    return (
      <Grid10Row>
        <Grid10Cell spanx={10} spany={spany} >
          {mode === 'code' ? (
            <div style={{
              height: '400px',
            }}>
              <Editor
                height='100%'
                loading={<ProgressBar />}
                value={jsonObject}
                theme={theme}
                options={{
                  readOnly: true,
                  lineNumbers: 'off',
                  minimap: {
                    enabled: false
                  }
                }}
              />
            </div>
          ) : (
            <div style={{
              height: '200px',
              padding: '10px',
              overflow: 'auto'
            }}>
              {/* <JsonDisplay json={jsonObject} /> */}
            </div>
          )}
        </Grid10Cell>
      </Grid10Row>
    );
  }
  const renderFoot = (): JSX.Element => {
    if (more === 'more') {
      return (<></>);
    }
    return (
      <Grid10Row>
        <Grid10CellButton tip='json' icon='json' onClick={async () => { setMode('json') }} selected={mode==='json'} space />
        <Grid10CellButton tip='code' icon='code' onClick={async () => { setMode('code') }} selected={mode==='code'}space />
        <Grid10Cell spanx={7} space>&nbsp;</Grid10Cell>
        {/* {is(mode === 'code') ? (
          <Grid10CellButton tip='json' icon='json' onClick={async () => { setMode('json') }} space />
        ) : (
          <Grid10CellButton tip='code' icon='code' onClick={async () => { setMode('code') }} space />
        )} */}
        <Grid10CellButton tip={expand} icon={expand} onClick={doExpand} space />
      </Grid10Row>
    );
  }
  return (
    <>
      {renderHead()}
      {renderMore()}
      {renderFoot()}
    </>
  );
};


