import { type AiosStatus, is, isFail, isOk, isWait, set } from 'aios';
import { getClass } from 'signals';
import { GridCell, type GridCellProps } from './GridCell';
import { getIcon } from 'components/getIcon';

export interface GridCellIconProps extends GridCellProps {
  name?: string;
  icon?: string;
  status?: AiosStatus;
}

export function getIconProps(props: GridCellIconProps): { icon: JSX.Element, color: string, blink: boolean } {
  const copy = { ...props };
  let color = '';
  let blink = false;
  if (is(copy.icon)) {
    copy.name = copy.icon;
  }
  if (is(copy.status)) {
    if (isOk(copy.status)) {
      copy.name = 'ok';
    } else {
      if (isWait(copy.status)) {
        copy.name = 'wait';
      } else {
        if (isFail(copy.status)) {
          copy.name = 'fail';
        }
      }
    }
  }
  const icon = getIcon(copy.name);
  const lower = is(copy.name) ? copy.name.toLowerCase() : '';
  switch (lower) {
    case 'confirm':
      color = 'green';
      blink = true;
      break;
    case 'decline':
      color = 'red';
      break;
    case 'fail':
      color = 'red';
      break;
    case 'wait':
      color = 'yellow';
      blink = true;
      break;
  }
  return {
    icon,
    color: set(copy.options?.color, color),
    blink: set(copy.options?.blink, blink)
  };
}

export const GridCellIcon = (props: GridCellIconProps): JSX.Element => {
  const { icon, color, blink } = getIconProps(props);
  return (
    <GridCell {...props}>
      <div className={getClass('grid-cell-icon', {
        color: set(props.options?.color, color),
        blink: set(props.options?.blink, blink),
      })}>
        {icon}
      </div>
    </GridCell>
  );
}