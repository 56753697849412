import is from '../is';

export function remove<T>(
  array: T[] | undefined,
  item: T | undefined | null,
): T[] | undefined {
  if (!is(item) || array === undefined || array === null) {
    return array;
  }
  const index = array.indexOf(item);
  if (index !== -1) {
    array.splice(index, 1);
  }
  return is(array) ? array : undefined;
}

export default remove;
