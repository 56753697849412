import { useApp, useRoute } from 'signals';
import { createAiosPath, is, isFail, isWait } from 'aios';
import { useState } from 'react';
import { AppDataFoot } from './AppDataFoot';
import { AppDataNodeHead } from './AppDataNodeHead';
import { Grid10 } from 'components';
import { getAiosNodeAction } from 'app/AiosNode/AiosNodeApi';
import { findAiosNode } from 'app';

export const AppData = (): JSX.Element => {
  const { root } = useApp();
  const { path, goTo } = useRoute();
  const [isDragging, setIsDragging] = useState(false);
  const node = findAiosNode(root, path);
  function handleDragOver(e: React.DragEvent<HTMLDivElement>): void {
    e.preventDefault();
    setIsDragging(true);
  }
  function handleDragLeave(e: React.DragEvent<HTMLDivElement>): void {
    e.preventDefault();
    setIsDragging(false);
  }
  function handleDrop(e: React.DragEvent<HTMLDivElement>): void {
    e.preventDefault();
    setIsDragging(false);
    const upload = async (): Promise<void> => {
      const commands = node?.ui?.commands;
      if (!is(commands)) {
        return;
      }
      const upload = commands.find(command => command.name === 'upload');
      if (!is(upload)) {
        return;
      }
      if (is(upload.doUpload)) {
        const handled = await upload.doUpload(e.dataTransfer.files);
        if (is(handled)) {
          return;
        }
      }
      if (is(upload.path)) {
        goTo(createAiosPath({ path: upload.path }));
      }
    }
    void upload();
  };
  const render = (): JSX.Element => {
    if (path.path === '' || path.path === '/') {
      return (<div>AppData no path</div>);
    }
    if (!is(node)) {
      return (<div>AppData no node</div>);
    }
    const { ui } = node;
    const Form = ui?.form;
    if (is(Form)) {
      return (<Form node={node} />);
    }
    let icon = 'aios';
    let spin;
    const { status } = getAiosNodeAction(node);
    if (isWait(status)) {
      spin = true;
    }
    if (isFail(status)) {
      icon = 'fail';
    }
    return (
      <Grid10>
        <AppDataNodeHead
          node={node}
          icon={icon}
          spin={spin}
          path={path.full}
        />
      </Grid10>
    );
  }
  return (
    <div
      className={`app-data${!isDragging ? '' : ' drag'}`}
      onDragOver={handleDragOver}
      onDragLeave={handleDragLeave}
      onDrop={handleDrop}
    >
      <div className='app-data-container'>
        {render()}
      </div>
      <AppDataFoot />
    </div>
  );
};