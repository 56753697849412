import {
    type AiosAsset,
    AiosType,
    createAiosAccount,
    createAiosAsset,
    is,
    remove,
    append,
} from 'aios';
import { type AiosNode, createAiosNode } from './AiosNode';

export function cloneAiosNode(node: AiosNode, text?: string): AiosNode {
    const cloneNode = (cloneNode: AiosNode): AiosNode => {
        const cloned = createAiosNode(cloneNode);
        const { type, item, nodes } = cloned;
        if (is(item)) {
            switch (type) {
                case AiosType.Account:
                    cloned.item = createAiosAccount(item);
                    break;
                case AiosType.Asset:
                    {
                        const asset = createAiosAsset(item);
                        if (is(text)) {
                            asset.file = { ...asset?.file, text }

                        }
                        cloned.item = asset;
                    }
                    break;
            }
        }
        if (is(nodes)) {
            cloned.nodes = [];
            for (let i = 0; i < nodes.length; i++) {
                const child = nodes[i];
                if (is(child.pathEx.action)) {
                    continue;
                }
                cloned.nodes?.push(cloneAiosNode(child));
            }
        }
        return cloned;
    }
    const configureNode = (configue: AiosNode): void => {
        const { type, item, nodes } = configue;
        if (is(item) && is(nodes)) {
            switch (type) {
                case AiosType.Asset:
                    {
                        const asset = item as AiosAsset;
                        const assets: AiosAsset[] = [];
                        nodes.forEach(child => {
                            if (child.type === AiosType.Asset) {
                                const childAsset = child.item as AiosAsset;
                                if (is(childAsset)) {
                                    assets.push(childAsset);
                                }
                            }

                        });
                        asset.assets = assets;
                    }
                    break;
            }
        }
        if (is(nodes)) {
            nodes.forEach(child => { configureNode(child); });
        }
    }
    const clone = cloneNode(node);
    configureNode(clone);
    return clone;
}

export function storeAiosNode(node: AiosNode): void {
    node.store = cloneAiosNode(node);
}

export function restoreAiosNode(node: AiosNode): void {
    if (!is(node.store)) {
        return;
    }
    node.item = node.store.item;
    node.nodes = node.store.nodes;
    node.store = undefined;
    node.clones = undefined;
    node.clonei = undefined;
}

export function createAiosNodeClone(node: AiosNode, text?: string): void {
    if (!is(node.store)) {
        storeAiosNode(node);
    }
    const clone = cloneAiosNode(node, text);
    let clonei = 1;
    if (node.clonei === undefined) {
        node.clones = append(node.clones, cloneAiosNode(node));
        node.clones = append(node.clones, clone);
        node.clonei = 1;
    } else {
        node.clones = append(node.clones, clone);
        clonei = node.clonei + 1;
    }
    setAiosNodeCloneIndex(node, clonei);
    // node.nodes = clone.nodes;
}

export function deleteAiosNodeClone(node: AiosNode): void {
    let { clonei } = node;
    const { clones } = node;
    if (clonei === undefined || !is(clones)) {
        return;
    }
    if (clones.length === 1) {
        node.clonei = undefined;
        node.clones = undefined;
        restoreAiosNode(node);
        return;
    }
    const removeClone = clones[clonei];
    node.clones = remove(node.clones, removeClone);
    if (clonei > 0) {
        clonei = clonei - 1;
    }
    setAiosNodeCloneIndex(node, clonei);

    // node.clonei = clonei;
    // const clone = clones[clonei];
    // node.item = clone.item;
    // node.nodes = clone.nodes;
}

// export function addAiosNodeCloneIndex(node: AiosNode, add: number): void {
//     let { clonei } = node;
//     const { clones } = node;
//     if (!is(clones)) {
//         return;
//     }
//     if (!is(clonei)) {
//         clonei = 0;
//     }
//     clonei += add;
//     setAiosNodeCloneIndex(node, clonei);
// }

export function setAiosNodeCloneIndex(node: AiosNode, clonei: number): void {
    const { clones } = node;
    if (!is(clones)) {
        return;
    }
    if (!is(clonei)) {
        clonei = 0;
    }
    if (clonei < 0) {
        clonei = clones.length - 1;
    } else {
        if (clonei >= clones.length) {
            clonei = 0;
        }
    }
    node.clonei = clonei;
    const clone = clones[clonei];
    const { nodes } = node;
    const { nodes: cloneNodes } = clone;
    if (is(nodes) && is(cloneNodes)) {
        cloneNodes.forEach(cloneChild => {
            const child = nodes.find(child => child.path === cloneChild.path);
            if (is(child)) {
                cloneChild.ui = child.ui;
            }
        });
    }
    node.nodes = clone.nodes;
}