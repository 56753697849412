import { type AiosPath, AiosStatus, createAiosPath, is, isFail, isWait } from 'aios';
import { type AiosNode } from 'app';
import { AuthState } from 'signals/AppState/AuthState';
import { AppState } from 'signals/AppState/AppState';
import { getAiosNodeAction, setAiosNodeAction, addAiosNodeAction } from 'app/AiosNode/AiosNodeApi';
import { setAiosNodeUiData } from 'app/AiosNode/AiosNodeUi';
import { doReload } from './loadNodeAssetFunctions';
import { doSignIn, doSignInCancel, doSignOutCancel, doSignOutConfirm } from './loadNodeAiosFunctions';
import { AuthFormAws } from 'components';
import { AppDataNode } from 'views';

export async function loadNodeAios(
  node: AiosNode,
  target: AiosPath,
): Promise<AiosNode | undefined> {
  const { action } = target;
  switch (action) {
    case 'auth':
    case 'signin':
      await loadAiosSignIn(node);
      return node;
    case 'signout':
      await loadAiosSignOut(node);
      return node;
    default:
      await loadAiosDefault(node);
      return node;
  }
}

async function loadAiosDefault(node: AiosNode): Promise<void> {
  const { output, status, loaded } = getAiosNodeAction(node);
  if (!is(loaded)) {
    if (!isFail(status)) {
      if (!isWait(status)) {
        setAiosNodeAction(node, { text: 'loading aios', status: AiosStatus.Processing, progress: 0.1 });
        setAiosNodeUiData(node, { form: AppDataNode, functions: { cancel: async () => { await doSignInCancel(node); } } });
        return;
      }
      const action = setAiosNodeAction(node, { text: 'loading aios', status: AiosStatus.Ok, loaded: true });
      addAiosNodeAction(node, action);
      setAiosNodeAction(node);
    }
    else {
      setAiosNodeUiData(node, { form: AppDataNode, functions: { reload: async () => { await doReload(node); } } });
      return;
    }
  }
  if (!isWait(status) || output.text.includes('loading')) {
    setAiosNodeAction(node);
    if (!is(AuthState.current.account)) {
      setAiosNodeUiData(node, { form: AppDataNode, functions: { login: doSignIn } });
    } else {
      setAiosNodeUiData(node, { form: AppDataNode, functions: { logout: async () => { await doSignOutConfirm(node); } } });
    }
    return;
  }
  setAiosNodeUiData(node, { form: AppDataNode, functions: { cancel: undefined } });
}

async function loadAiosSignIn(node: AiosNode): Promise<void> {
  const { action, output } = getAiosNodeAction(node);  
  const signIn = await AuthState.current.signIn();
  if (signIn === false) {
    output.setFail();
    addAiosNodeAction(node, action);
    setAiosNodeAction(node);
    AppState.current.goTo(createAiosPath({ path: 'aios' }));
    return;
  }
  if (is(signIn)) {
    addAiosNodeAction(node, action);
    setAiosNodeAction(node);
    AppState.current.goTo(createAiosPath({ path: 'aios' }));
    return;
  }
  if (!is(output)) {
    setAiosNodeAction(node, { text: 'signing in', status: AiosStatus.Processing, progress: 0.1 });
    // setAiosNodeUiData(node, { form: AppDataNode, functions: { cancel: doSignInCancel } });
    // return;
  }
  setAiosNodeUiData(node, { form: AuthFormAws, functions: { cancel: doSignInCancel } });
}

async function loadAiosSignOut(node: AiosNode): Promise<void> {
  const { status } = getAiosNodeAction(node);
  if (status !== AiosStatus.Processing) {
    setAiosNodeAction(node, { text: 'sign out? - local cache will be cleared', status: AiosStatus.Processing, progress: 0.6 });
  }
  setAiosNodeUiData(node, {
    functions: {
      decline: async () => {
        await doSignOutCancel(node);
        AppState.current.goTo(node.pathEx);
      },
      confirm: async () => { await doSignOutConfirm(node); }
    }
  });
}
