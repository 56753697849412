import { useApp } from 'signals';
import { is } from 'aios';
import { AmplifyProvider, Authenticator, type Theme, defaultTheme } from '@aws-amplify/ui-react';
import '@aws-amplify/ui-react/styles.css';
import { I18n } from 'aws-amplify';

I18n.putVocabularies({
  en: {
    'Username': 'Email',
    'Enter your Username': 'Enter your Email',
  },
});

export const AuthFormAws = (): JSX.Element => {
  const { settings } = useApp();
  const theme: Theme = {
    name: 'dark-mode-theme',
    overrides: [
      {
        colorMode: 'dark',
        tokens: {
          colors: {
            neutral: {
              10: defaultTheme.tokens.colors.neutral[100],
              20: defaultTheme.tokens.colors.neutral[90],
              40: defaultTheme.tokens.colors.neutral[80],
              80: defaultTheme.tokens.colors.neutral[40],
              90: defaultTheme.tokens.colors.neutral[20],
              100: defaultTheme.tokens.colors.neutral[10],
            },
            black: { value: '#fff' },
            white: { value: '#000' },
            overlay: {
              10: { value: 'hsla(0, 0%, 100%, 0.1)' },
              20: { value: 'hsla(0, 0%, 100%, 0.2)' },
              30: { value: 'hsla(0, 0%, 100%, 0.3)' },
              40: { value: 'hsla(0, 0%, 100%, 0.4)' },
              50: { value: 'hsla(0, 0%, 100%, 0.5)' },
              60: { value: 'hsla(0, 0%, 100%, 0.6)' },
              70: { value: 'hsla(0, 0%, 100%, 0.7)' },
              80: { value: 'hsla(0, 0%, 100%, 0.8)' },
              90: { value: 'hsla(0, 0%, 100%, 0.9)' },
            },
          },
        },
      },
    ],
  };
  return (
    <AmplifyProvider theme={theme} colorMode={!is(settings.dark) ? 'dark' : 'light'}>
      <Authenticator />
    </AmplifyProvider>
  );
};
