import {
  type AiosData,
  AiosStatus,
  AiosType,
  is,
  delay,
  isWait,
  AiosDataCommand,
  createAiosDataInput,
  createAiosAction,
  createAiosDataOutput,
  createAiosPath,
  type AiosAsset,
  isOk,
  append,
  createAiosAsset,
  createAiosFile,
} from 'aios';
import { createAiosNode, type AiosNode } from 'app';
import { addAiosNodeAction, getAiosNodeAction, setAiosNodeAction } from 'app/AiosNode/AiosNodeApi';
import { getAiosNodeData } from 'app/AiosNode/AiosNodeData';
import { AppState } from 'signals/AppState/AppState';
import { createAiosClientApp } from 'utl';

export interface ChatProcess {
  text: string;
  files?: Array<{ name: string; text: string }>;
}

export async function doChat(node: AiosNode, input?: string): Promise<void> {
  // if (!is(input)) {
  //   return;
  // }
  const text = input;
  const { action } = getAiosNodeAction(node);
  const files = getFiles(node);
  // if (!is(files)) {
  const obj = { text, files, result: action?.output?.text };
  const json = JSON.stringify(obj);
  // }
  setAiosNodeAction(node, {
    action: createAiosAction(
      {
        path: 'aios?action=chat',
        input: createAiosDataInput<AiosData>({
          type: AiosType.ActionChat,
          item: { id: 'user', text, json },
          command: AiosDataCommand.Chat
        }),
        output: createAiosDataOutput<AiosData>({
          type: AiosType.ActionChat,
          item: { id: 'aios', text: '...' },
          status: AiosStatus.Processing,
          progress: 1.0,
        }),
      }
    )
  });
  void delay(async () => {
    await doChatData(node);
  });
  AppState.current.refresh();
}

export async function doChatCancel(node: AiosNode): Promise<void> {
  const { action, output } = getAiosNodeAction(node);
  if (!is(output)) {
    return;
  }
  if (output.progress === 0.6) {
    output.setFail({ status: AiosStatus.FailCancel, text: 'cancelled' });
    addAiosNodeAction(node, action);
  }
  if (is(node.api.client)) {
    node.api.client.cancel();
    node.api.client = undefined;
  }
  AppState.current.refresh();
}

async function doChatData(node: AiosNode): Promise<void> {
  const { action, input, output } = getAiosNodeAction(node);
  if (!is(action) || !is(input) || !is(output)) {
    return;
  }
  if (!isWait(output.status)) {
    return;
  }
  output.progress = 0.7;
  const path = createAiosPath({ path: node.pathEx.path, action: 'chat' });
  node.api.client = createAiosClientApp();
  const chatOutput = await node.api.client.send({ path, input });
  node.api.client = undefined;
  if (isOk(chatOutput.status)) {
    output.setOk();
    const item = output.item;
    const json = chatOutput.item?.text;
    if (is(json)) {
      const process: ChatProcess = JSON.parse(json) as ChatProcess;
      const { text, files } = process;
      if (is(item) && is(text)) {
        item.text = text;
      }
      setFiles(node, files);
    }
    // action.output = chatOutput;
    action.id = action.output?.item?.id;
  } else {
    output.setFail();
  }
  addAiosNodeAction(node, action);
  setAiosNodeAction(node);
  AppState.current.refresh();
}

// export async function doChatLoad(this: AiosNode): Promise<void> {
//   // const path = createAiosPath({ path: 'aios/chat' });
//   // const input = createAiosDataInput<AiosAction>({
//   //   type: AiosType.ActionChat,
//   //   command: AiosDataCommand.Load,
//   // });
//   // this.api.client = createAiosClientApp();
//   // this.process = await this.api.client.send({ path, input });
//   // const result = this.process.output;
//   // const items = result?.items;
//   // if (is(items)) {
//   //   for (const item of items) {
//   //     const chat = item as AiosAction;
//   //     this.interface.showHistory({
//   //       to: 'ai',
//   //       from: 'you',
//   //       text: chat.input,
//   //     });
//   //     this.interface.showHistory({
//   //       to: 'you',
//   //       from: 'ai',
//   //       text: chat.output,
//   //     });
//   //   }
//   // }
// }

function setFiles(node: AiosNode, files?: Array<{ name: string; text: string }>): void {
  if (!is(files)) { // || node.type !== AiosType.Asset) {
    return;
  }

  const assetNode = node?.nodes?.[0];
  if (is(assetNode) && assetNode.type === AiosType.Asset) {
    // if more than 1, create siblings?
    for (let i = 0; i < files.length; i++) {
      const file = files[i];
      const { name, text } = file;
      if (is(name) && is(text)) {        
        const { assetPath } = getAiosNodeData(assetNode);
        if (is(assetPath) && assetPath.toLowerCase().includes(name.toLowerCase())) {
          const { assetFile } = getAiosNodeData(assetNode);
          if (is(assetFile)) {
            assetFile.text = text;
          }
        }
      }
    }
    return;
  }

  const container = node?.nodes?.[0];
  if (!is(container)) {
    return;
  }
  const asset = container.item as AiosAsset;
  if (!is(asset)) {
    return;
  }


  // CREATE CONTAINER
  // CLONE ITEMS
  // CREATE NEW FILE
  // UPDATE EXISTING FILE

  const { nodes } = container;

  for (let i = 0; i < files.length; i++) {
    let handled = false;
    const file = files[i];
    const { name, text } = file;
    if (is(name) && is(text)) {
      if (is(nodes)) {
        for (let j = 0; j < nodes.length; j++) {
          const child = nodes[j];
          const childPath = child.pathEx?.path;
          if (is(childPath) && childPath.includes(name)) {
            const { assetFile } = getAiosNodeData(child);
            if (is(assetFile)) {
              assetFile.text = text;
            }
            handled = true;
            break;
          }
        }
      }
    }
    if (!is(handled)) {
      const parentPath = asset.path as string;
      const path = `${parentPath}/${name}`;
      const pathEx = createAiosPath({ path });
      const child = createAiosNode({ parent: container, path, pathEx });
      child.type = AiosType.Asset;
      child.item = createAiosAsset({
        path,
        name,
        file: createAiosFile({
          type: pathEx.fileType,
          text
        }),
      });
      asset.assets = append(asset.assets, child.item);
      container.nodes = append(container.nodes, child);
    }
  }
}

function getFiles(node: AiosNode): Array<{ name: string; text: string }> {
  const files: Array<{ name: string; text: string }> = [];
  let subject: AiosNode | undefined = node;
  if (!is(subject.pathEx?.action)) {
    return files;
  }
  subject = node?.nodes?.[0];
  if (!is(subject)) {
    return files;
  }
  if (subject.type === AiosType.Asset) {
    const name = subject.pathEx?.name;
    const text = getAiosNodeData(subject).assetFileText;
    if (is(name) && is(text)) {
      files.push({ name, text });
    }
    return files;
  }
  const { nodes } = subject;
  if (!is(nodes)) {
    return files;
  }

  for (let i = 0; i < nodes.length; i++) {
    const child = nodes[i];
    const { assetPath, assetFileText } = getAiosNodeData(child);
    if (!is(assetPath) || !is(assetFileText)) {
      continue;
    }
    files.push({ name: assetPath, text: assetFileText });
  }
  return files;
}