import {
    type AiosAccount,
    type AiosAsset,
    type AiosAccess,
    type AiosPath,
    AiosType,
    set,
    createAiosPath,
} from 'aios';
import { type AiosNodeApi } from './AiosNodeApi';
import { type AiosNodeUi } from './AiosNodeUi';

export type AiosNodeItem = AiosAccount | AiosAsset | AiosAccess;

export interface AiosNode {
    pathEx: AiosPath;
    path: string;
    type: AiosType;
    item?: AiosNodeItem;
    items?: AiosNodeItem[];
    nodes?: AiosNode[];
    parent?: AiosNode;
    store?: AiosNode;
    clones?: AiosNode[];
    clonei?: number;
    api: AiosNodeApi;
    ui: AiosNodeUi;
}

export function createAiosNode(input?: Partial<AiosNode>): AiosNode {
    const node: AiosNode = {
        pathEx: set(input?.pathEx, createAiosPath()),
        path: set(input?.path, set(input?.pathEx?.full, '')),
        type: set(input?.type, AiosType.None),
        item: set(input?.item, undefined),
        nodes: set(input?.nodes, undefined),
        parent: set(input?.parent, undefined),
        store: set(input?.store, undefined),
        clones: set(input?.clones, undefined),
        clonei: set(input?.clonei, undefined),
        api: {
            client: set(input?.api?.client, undefined),
            action: set(input?.api?.action, undefined),
            actions: set(input?.api?.actions, undefined),
            loaded: set(input?.api?.loaded, undefined),
        },
        ui: {

            input: set(input?.ui?.input, undefined),
            form: set(input?.ui?.form, undefined),
            schema: set(input?.ui?.schema, undefined),
            commands: set(input?.ui?.commands, undefined),
            selected: set(input?.ui?.selected, undefined),
            doInput: set(input?.ui?.doInput, undefined),
            doReset: set(input?.ui?.doReset, undefined),
            doSubmit: set(input?.ui?.doSubmit, undefined)
        }
    };
    return node;
}