import {
    AiosStatus,
    is,
    createAiosPath,
    isWait,
    AiosFileType,
    AiosType,
    AiosAssetSchema,
    append,
} from 'aios';
import { createAiosNode, type AiosNode } from '../../AiosNode';
import { getAiosNodeAction, setAiosNodeAction } from 'app/AiosNode/AiosNodeApi';
import { setAiosNodeUiData } from 'app/AiosNode/AiosNodeUi';
import { AppDataNode } from 'views';
import { createAiosAssetSample } from 'app/AiosNode/sample';
import { doCancelSave, saveNode, doSave } from '../save';

export async function loadNodeCreate(node: AiosNode): Promise<void> {
    const { progress, loaded } = getAiosNodeAction(node);
    if (!is(loaded)) {        
        const createNode = node.parent as AiosNode;
        const rootNode = createNode.parent as AiosNode;
        let newType = AiosFileType.Folder;
        if (rootNode.type === AiosType.Asset) {
            newType = AiosFileType.Code;
        }
        const newAsset = createAiosAssetSample(newType, rootNode?.pathEx.path as string);
        const newPath = createAiosPath({ path: newAsset.path });
        const newNode = createAiosNode({
            parent: node,
            type: AiosType.Asset,
            path: newPath.path,
            pathEx: newPath,
            item: newAsset,
            api: { loaded: true },
        });
        if (!is(node.nodes)) {
            node.nodes = append(node.nodes, newNode)
        }
        if (is(node.api)) {
            node.api.loaded = true;
        }
        if (is(node.parent)) {
            node.parent.api.loaded = true;
        }
    }
    if (!is(progress) || progress < 0.6) {
        setAiosNodeAction(node, { text: 'creating asset', status: AiosStatus.Ok, loaded: true });
        setAiosNodeUiData(node, { form: AppDataNode, schema: AiosAssetSchema, functions: { close: doCancelSave, save: doSave } });
        const child = node.nodes?.[0];
        if (is(child)) {
            setAiosNodeUiData(child, { form: AppDataNode, schema: AiosAssetSchema });
        }
        return;
    }
    if (progress < 1.0) {
        await saveNode(node);
        const { status } = getAiosNodeAction(node);
        if (isWait(status)) {
            setAiosNodeUiData(node, { form: AppDataNode, schema: AiosAssetSchema, functions: { cancel: doCancelSave } });
            return;
        }
        setAiosNodeUiData(node, { form: AppDataNode, schema: AiosAssetSchema, functions: { close: doCancelSave, save: doSave } });
    }
}

