import {
  AiosStatus,
  is,
  isFail,
  AiosType,
  append,
  createAiosAccount,
  createAiosPath,
  isWait,
  AiosAccountSchema,
} from 'aios';
import { createAiosNode, findAiosNode, type AiosNode } from '../../AiosNode';
import { addAiosNodeAction, getAiosNodeAction, setAiosNodeAction } from 'app/AiosNode/AiosNodeApi';
import { loadNodeData } from 'app/funcs/load/loadNodeData';
import { setAiosNodeUiData } from 'app/AiosNode/AiosNodeUi';
import { AppDataNode } from 'views';
import { AppState } from 'signals/AppState/AppState';
import { doReload } from './loadNodeAssetFunctions';
import { loadNodeDelete } from './loadNodeDelete';
import { doNew } from '../save';

export async function loadNodeAccount(node: AiosNode): Promise<void> {
  if (!is(node.pathEx.action)) {
    await loadNodeAccountDefault(node);
    return;
  }
  await loadNodeAccountAction(node);
}

async function loadNodeAccountDefault(node: AiosNode): Promise<void> {
  const { status, progress, loaded } = getAiosNodeAction(node);
  if (is(loaded)) {
    setAiosNodeUiData(node, { form: AppDataNode, functions: { create: doNew, delete: undefined } });
    return;
  }
  if (isFail(status)) {
    setAiosNodeUiData(node, { form: AppDataNode, functions: { reload: doReload } });
    return;
  }
  if (!isWait(status)) {
    setAiosNodeAction(node, { text: 'loading account', status: AiosStatus.Processing, progress: 0.1 });
    setAiosNodeUiData(node, { form: AppDataNode, functions: { cancel: undefined } });
    return;
  }
  if (progress === 0.1) {
    setAiosNodeAction(node, { text: 'loading account', status: AiosStatus.Processing, progress: 0.2 });
    await loadAccountDefaultData(node);
  }
  setAiosNodeUiData(node, { form: AppDataNode, functions: { create: doNew, delete: undefined } });
}

async function loadAccountDefaultData(node: AiosNode): Promise<void> {
  const success = await loadNodeData(node);
  const { action } = getAiosNodeAction(node);
  addAiosNodeAction(node, action);
  setAiosNodeAction(node);
  if (!is(success)) {
    return;
  }
  const account = createAiosAccount(node.item);
  if (is(account?.assets)) {
    for (let i = 0; i < account.assets.length; i++) {
      const child = account.assets[i];
      let childPath = createAiosPath({ path: child.path });
      childPath = createAiosPath({
        path: `${node.pathEx?.path as string}/${childPath.name as string}`,
      });
      let childNode = findAiosNode(node, childPath);
      if (!is(childNode)) {
        childNode = createAiosNode();
        node.nodes = append(node.nodes, childNode);
      }
      childNode.parent = node;
      childNode.pathEx = childPath;
      childNode.type = AiosType.Asset;
      childNode.item = child;
    }
  }
}

async function loadNodeAccountAction(node: AiosNode): Promise<void> {
  const { action } = node.pathEx;
  if (action === 'create') {
    AppState.current.goTo(createAiosPath({ full: `${node.pathEx.path as string}/container?action=create` }));
    return;
  }
  if (action === 'update') {
    setAiosNodeUiData(node, { form: AppDataNode, schema: AiosAccountSchema });
    setAiosNodeAction(node, { text: 'creating asset', status: AiosStatus.Ok, loaded: true });
    return;
  }
  if (action === 'delete') {
    await loadNodeDelete(node);
    return;
  }
  setAiosNodeUiData(node, { form: AppDataNode, functions: { reload: doReload } });
}


