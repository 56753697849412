import { MDXEditor, toolbarPlugin, headingsPlugin, listsPlugin, linkPlugin, quotePlugin, thematicBreakPlugin, imagePlugin, UndoRedo, BoldItalicUnderlineToggles, type MDXEditorMethods, diffSourcePlugin, InsertImage } from '@mdxeditor/editor'
import { is, set } from 'aios';
import { type AiosNode } from 'app';
import { useEffect, useRef, useState } from 'react';
import { useApp, useRoute } from 'signals';
import '@mdxeditor/editor/style.css';

export interface AppDataMarkdownProps {
  node: AiosNode;
  value: string;
  onValue?: (value?: string) => void;
  disabled?: boolean;
}

export const AppDataMarkdown: (props: AppDataMarkdownProps) => JSX.Element = ({ value, onValue, disabled }: AppDataMarkdownProps) => {
  const { settings } = useApp();
  const ref = useRef<MDXEditorMethods>(null);
  const [useValue, setUseValue] = useState(set(value, 'no value'));
  const { path } = useRoute();
  const { action } = path;
  const className = !is(settings.dark) ? 'dark-theme' : undefined;
  useEffect(() => {
    const usefileText = set(value, 'no value');
    setUseValue(usefileText);
    if (is(ref.current)) {
      ref.current.setMarkdown(usefileText);
    }
  }, [value, disabled]);
  const onChange = (v: string | undefined): void => {
    setUseValue(set(v, 'no value'));
    if (is(onValue)) {
      onValue(v);
    }
  };
  if (!is(action) || is(disabled)) {
    return (
      <MDXEditor
        ref={ref}
        className={className}
        markdown={useValue}
        plugins={[
          headingsPlugin(),
          imagePlugin(),
          listsPlugin(),
          quotePlugin(),
          thematicBreakPlugin(),
          linkPlugin()
        ]}
      />);
  }
  return (
    <MDXEditor
      ref={ref}
      className={className}
      markdown={useValue}
      plugins={[
        headingsPlugin(),
        imagePlugin(),
        listsPlugin(),
        quotePlugin(),
        thematicBreakPlugin(),
        linkPlugin(),
        diffSourcePlugin(),
        toolbarPlugin({
          toolbarContents: () => (
            <>
              <UndoRedo />
              <BoldItalicUnderlineToggles />
              <InsertImage />
            </>
          )
        })
      ]}
      onChange={onChange}
    />);
};
