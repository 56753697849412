import is from '../is';

export function append<T>(
  array: T[] | undefined,
  item: T | undefined | null,
): T[] | undefined {
  if (!is(item)) {
    return array;
  }
  if (array === undefined || array === null) {
    return [item];
  }
  array.push(item);
  return array;
}

export default append;
