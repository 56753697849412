import { AiosStatus, createAiosPath, is, remove } from 'aios';
import { type AiosNode } from 'app';
import { AppState } from 'signals/AppState/AppState';
import { setAiosNodeAction, getAiosNodeAction } from 'app/AiosNode/AiosNodeApi';

export async function doCancelAction(node: AiosNode): Promise<void> {
  const parent = node.parent;
  if (is(parent)) {
    parent.nodes = remove(parent.nodes, node);
    AppState.current.goTo(parent.pathEx);
  }
}

export async function doCancelSave(node: AiosNode): Promise<void> {
  const action = node.pathEx.action;
  const actionNode = node.parent;
  const parentNode = actionNode?.parent;
  if (!is(actionNode) || !is(parentNode)) {
    return;
  }  
  if (action === 'create') {
    parentNode.nodes = remove(parentNode.nodes, actionNode);
    AppState.current.goTo(createAiosPath({ path: parentNode.pathEx.path }));
    return;
  }
  if (action === 'update'){
    actionNode.nodes = remove(actionNode.nodes, node);
    AppState.current.goTo(createAiosPath({ path: actionNode.pathEx.path }));
  }  
}

export async function doNew(node: AiosNode): Promise<void> {
  let name = 'new0';
  const { nodes } = node;
  if (is(nodes)) {
    let maxNumber = -1;
    for (let i = 0; i < nodes.length; i++) {
      const child = nodes[i];
      const path = child.pathEx.path;
      if (is(path) && path.includes('new')) {
        const index = path.indexOf('new');
        const numStr = path.slice(index + 3);
        const num = parseInt(numStr, 10);
        if (!isNaN(num)) {
          maxNumber = Math.max(maxNumber, num);
        }
      }
    }
    name = `new${maxNumber + 1}`;
  }
  const full = `${node.pathEx.path as string}/${name}?action=create`;
  AppState.current.goTo(createAiosPath({ full }));
}

export async function doSave(node: AiosNode): Promise<void> {
  setAiosNodeAction(node, { text: 'saving', status: AiosStatus.Processing, progress: 0.6 });
  AppState.current.refresh();
}

export async function doSaveCancel(node: AiosNode): Promise<void> {
  const { output } = getAiosNodeAction(node);
  output.setFail({ status: AiosStatus.FailCancel });
  // todo: still need to wait for client to finish for no rouge finishes
  // if (is(this.api.client)) {
  //   this.api.client.cancel();
  // }
}

// export async function doSaveFinish(node: AiosNode): Promise<void> {
//   const asset = node.nodes?.[0]?.item as AiosAsset;
//   const actionNode = node.parent as AiosNode;
//   const rootNode = actionNode.parent as AiosNode;
//   if (is(rootNode)) {
//     if (is(actionNode)) {
//       rootNode.nodes = remove(rootNode.nodes, actionNode);
//     }
//   }
//   if (is(asset)) {
//     const path = createAiosPath({ path: asset.path });
//     AppState.current.goTo(path);
//   }
// }