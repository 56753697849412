import { getClass } from 'signals';
import './Grid10.css';
import { is } from 'aios';

export interface Grid10Props {
  middle?: boolean;
  children: React.ReactNode;
}

export const Grid10 = ({ middle, children }: Grid10Props): JSX.Element => {
  let className = 'grid10';
  if (is(middle)) {
    className = 'grid10 middle';
  }
  return (
    <div className={getClass(className)}>
      {children}
    </div>
  );
}