import { type AiosType, type AiosAction, is } from 'aios';
import { type AiosNode, type AiosNodeItem } from './AiosNode';
import { createAppCache } from 'utl';

interface AppCacheItem {
    item?: AiosNodeItem;
    actions?: AiosAction[];
}

export async function setAiosNodeCache(node: AiosNode): Promise<void> {
    const path = node.pathEx.path as string;
    if (!is(path)) {
        return;
    }
    const item = node.item;
    const actions = node.api.actions;
    const jsonString = JSON.stringify({ item, actions });
    const cache = createAppCache();
    await cache.set(path, jsonString);
}

export async function getAiosNodeCache(node: AiosNode): Promise<boolean> {
    const path = node.pathEx.path as string;
    if (!is(path)) {
        return false;
    }
    const cache = createAppCache();
    const cached = await cache.get(path);
    if (!is(cached)) {
        return false;
    }
    const jsonObject = JSON.parse(cached) as AppCacheItem;
    node.item = jsonObject.item;
    node.api.actions = jsonObject.actions;
    node.type = node.pathEx.aiosType as AiosType;
    return true;
}

export async function remAiosNodeCache(node: AiosNode): Promise<void> {
    const path = node.pathEx.path as string;
    if (!is(path)) {
        return;
    }
    const cache = createAppCache();
    await cache.rem(path);
}
