import { type AiosNode } from '..';
import { is, set, type AiosObject } from 'aios';
import { type AppDataType } from 'views';

export interface AiosNodeUi {
    input?: string;
    form?: AppDataType;
    schema?: AiosObject;
    commands?: AiosNodeUiControl[];
    selected?: boolean;
    doInput?: (input: string) => void;
    doReset?: () => void;
    doSubmit?: () => Promise<void>;
    
    view?: string;
    mode?: string;
    more?: string;
    expand?: string;
}

export interface AiosNodeUiControl {
    name?: string;
    path?: string;
    icon?: string;
    color?: string;
    blink?: boolean;
    disabled?: boolean;
    selected?: boolean;
    downloadUrl?: string;
    downloadName?: string;
    doChange?: (node: AiosNode, input?: string) => Promise<void>;
    doUpload?: (files: FileList) => Promise<boolean>;
}

interface AiosFunctions extends Record<string, ((node: AiosNode) => Promise<void>) | undefined> { }

export function setAiosNodeUi(node: AiosNode, ui: Partial<AiosNodeUi>): void {
    node.ui.view = set(ui?.view, node.ui.view);
    node.ui.mode = set(ui?.mode, node.ui.mode);
    node.ui.more = set(ui?.more, node.ui.more);
    node.ui.expand = set(ui?.expand, node.ui.expand);    
}

export function getAiosNodeUi(node: AiosNode): Partial<AiosNodeUi> {
    const { view, mode, more, expand } = node.ui;
    return { view, mode, more, expand };
}

export function setAiosNodeUiData(node: AiosNode, { form, schema, functions }: { form?: AppDataType, schema?: AiosObject, functions?: AiosFunctions }): void {
    const path = node.pathEx.path as string;
    const commands: AiosNodeUiControl[] = [];
    if (is(functions)) {
        for (const name of Object.keys(functions)) {
            const func = functions[name];
            if (typeof func === 'function') {
                commands.push({
                    name,
                    doChange: func,
                });
            } else {
                commands.push({
                    name,
                    path: `${path}?action=${name}`,
                });
            }
        }
    }
    // if (is(form) && commands.length === 0) {
    //     if (is(node.ui.doSubmit)) {
    //         commands.push(
    //             {
    //                 name: 'save',
    //                 doChange: node.ui.doSubmit,

    //             });

    //     }
    //     // commands.push(
    //     //     {
    //     //         name: 'save',
    //     //         doChange: doSubmit,

    //     //     });
    //     commands.push(
    //         {
    //             name: 'cancel',
    //             path: node.pathEx.parent,
    //             doChange: doCancel,
    //         });
    // }
    node.ui = {
        form,
        schema,
        commands
    };
}

