import {
    AiosStatus,
    is,
    append,
    createAiosPath,
    isOk,
    isWait,
    AiosType,
} from 'aios';
import { createAiosNode, type AiosNode } from '../../AiosNode';
import { getAiosNodeAction, setAiosNodeAction } from 'app/AiosNode/AiosNodeApi';
import { setAiosNodeUiData } from 'app/AiosNode/AiosNodeUi';
import { AppDataNode } from 'views';
import { loadNodeNext } from './loadNodeNext';
import { doCancelAction } from '../save';
import { doRemoveConfirm, doRemoveFinish, removeNode } from '../remove';
import { AuthState } from 'signals/AppState/AuthState';

export async function loadNodeDelete(node: AiosNode): Promise<void> {
    const { progress } = getAiosNodeAction(node);
    const parent = node.parent as AiosNode;
    if (!is(progress) || progress < 0.1) {
        const deleteNode = createAiosNode({
            pathEx: node.pathEx,
            path: node.path,
            type: parent?.type,
            item: parent?.item,
            ui: {
                selected: true
            }
        });
        if (!is(node.nodes)) {
            node.nodes = append(node.nodes, deleteNode)
        }
        setAiosNodeAction(node, { text: 'preparing to confirm delete', status: AiosStatus.Processing, progress: 0.1 });
        setAiosNodeUiData(node, { functions: { cancel: doCancelAction } });
        return;
    }
    if (progress < 0.2) {
        setAiosNodeAction(node, { text: 'preparing to confirm delete .', status: AiosStatus.Processing, progress: 0.2 });
        setAiosNodeUiData(node, { functions: { cancel: doCancelAction } });
        return;
    }
    if (progress < 0.3) {
        setAiosNodeAction(node, { text: 'preparing to confirm  delete ..', status: AiosStatus.Processing, progress: 0.3 });
        setAiosNodeUiData(node, { functions: { cancel: doCancelAction } });
        const staged = await loadNodeNext(parent);
        const remNode = node.nodes?.[0] as AiosNode;
        stageNodes(remNode, parent);
        if (staged === undefined) {
            if (progress <= 0.2) {
                setAiosNodeAction(node, { text: 'preparing to confirm  delete .', status: AiosStatus.Processing, progress: 0.25 });
            } else {
                setAiosNodeAction(node, { text: 'preparing to confirm  delete ..', status: AiosStatus.Processing, progress: 0.2 });
            }
            setAiosNodeUiData(node, { form: AppDataNode, functions: { cancel: doCancelAction } });
            return;
        }
        if (!is(staged)) {
            setAiosNodeAction(node, { text: 'preparing to confirm  delete ...', status: AiosStatus.Fail, progress: undefined });
            setAiosNodeUiData(node, { form: AppDataNode, functions: { cancel: doCancelAction } });
            return;
        }
    }
    if (progress < 0.6) {
        setAiosNodeAction(node, { text: 'waiting for confirmation to delete', status: AiosStatus.Processing, progress: 0.5 });
        setAiosNodeUiData(node, { form: AppDataNode, functions: { cancel: doCancelAction, confirm: doRemoveConfirm } });
        return;
    }
    if (progress < 1.0) {
        await removeNode(node);
        const { status } = getAiosNodeAction(node);
        if (isWait(status)) {
            setAiosNodeUiData(node, { form: AppDataNode, functions: { cancel: doCancelAction } });
            return;
        }
        if (!isOk(status)) {
            setAiosNodeUiData(node, { form: AppDataNode, functions: { ok: doCancelAction } });
            return;
        }
        setAiosNodeUiData(node, { form: AppDataNode, functions: { ok: doRemoveFinish } });
        if (node.pathEx.aiosType !== AiosType.Account) {
            void doRemoveFinish(node);
            return;
        }
        await AuthState.current.signOut();
        window.location.href = '/aios';
        return;
    }
    setAiosNodeUiData(node, { form: AppDataNode, functions: { ok: doCancelAction } });
}

function stageNodes(actionNode: AiosNode, dataNode: AiosNode): void {
    const dataNodes = dataNode.nodes;
    if (!is(dataNodes)) {
        return;
    }
    const actionNodes = actionNode.nodes;
    for (let i = 0; i < dataNodes.length; i++) {
        const dataChild = dataNodes[i];
        if (is(dataChild.pathEx.action)) {
            continue;
        }
        if (is(actionNodes)) {
            let handled = false;
            for (let j = 0; j < actionNodes.length; j++) {
                const actionChild = actionNodes[j];
                if (dataChild.pathEx.path === actionChild.pathEx.path) {
                    stageNodes(actionChild, dataChild);
                    handled = true;
                    break;
                }
            }
            if (is(handled)) {
                continue;
            }
        }
        const deletePath = createAiosPath({ path: dataChild.pathEx.path, action: 'delete' });
        const deleteNode = createAiosNode({
            pathEx: deletePath,
            path: deletePath.full,
            type: dataChild.type,
            item: dataChild.item,
            ui: {
                selected: true
            }
        });
        actionNode.nodes = append(actionNode.nodes, deleteNode);
        stageNodes(deleteNode, dataChild);
    }
}