import {
  type AiosData,
  type AiosDataCommand,
  type AiosDataInput,
  type AiosDataOutput,
  type AiosStatus,
  type AiosType,
  createAiosData,
  set,
} from '../AiosUtil';

export interface AiosAction extends AiosData {
  input?: AiosDataInput<AiosData>;
  inputType?: AiosType;
  inputCommand?: AiosDataCommand;
  output?: Partial<AiosDataOutput<AiosData>>;
  outputStatus?: AiosStatus;
  accessId?: string;
  timeout?: number;
  size?: number;
  time?: number;
  cost?: number;
}

export function createAiosAction(options?: Partial<AiosAction>): AiosAction {
  const action = createAiosData(options) as AiosAction;
  action.input = set(options?.input, undefined);
  action.output = set(options?.output, undefined);
  action.accessId = set(options?.accessId, undefined);
  action.timeout = set(options?.timeout, undefined);
  action.size = set(options?.size, undefined);
  action.time = set(options?.time, undefined);
  action.cost = set(options?.cost, undefined);
  return action;
}
