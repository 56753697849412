import { type AppDataType, type AppDataProps } from './AppDataType';
import { setAiosNodeCloneIndex, type AiosNode, createAiosNodeChild, createAiosNodeClone, deleteAiosNodeClone } from 'app';
import { is, set, isWait, isFail, isOk, AiosFileType, createAiosPath, isFile, AiosType } from 'aios';
import { AppState } from 'signals/AppState/AppState';
import { Grid10 } from 'components';
import { AppDataNodeHead } from './AppDataNodeHead';
import { AppDataBody } from './AppDataBody';
import { getAiosNodeData, setAiosNodeData } from 'app/AiosNode/AiosNodeData';
import { getAiosNodeAction } from 'app/AiosNode/AiosNodeApi';
import { AppDataNodeFoot } from './AppDataNodeFoot';
import { getAiosNodeUi, setAiosNodeUi } from 'app/AiosNode/AiosNodeUi';

export const AppDataNode: AppDataType = ({ node, child, editing, disabled }: AppDataProps) => {
  if (!is(node)) {
    return (<div>no node</div>);
  }
  const { assetPath, assetFileType } = getAiosNodeData(node);
  let { view, mode, more, expand } = getAiosNodeUi(node);
  if (isFile(assetFileType)) {
    view = set(view, 'file');
    switch (assetFileType) {
      case AiosFileType.Markdown:
        mode = set(mode, 'markdown');
        break;
      default:
        mode = set(mode, 'code');
        break;
    }
    more = set(more, 'less');
    expand = set(expand, 'expand');
  }
  view = set(view, 'data');
  mode = set(mode, 'form');
  more = set(more, 'more');
  expand = set(expand, 'expand');
  setAiosNodeUi(node, { view, mode, more, expand });
  const onView = (newView: string): void => {
    let newMode = mode;
    if (newView === 'data') {
      newMode = 'form';
    } else {
      newMode = 'code';
    }
    setAiosNodeUi(node, { view: newView, mode: newMode });
    AppState.current.refresh();
  };
  const onMode = (newMode: string): void => {
    setAiosNodeUi(node, { mode: newMode });
    AppState.current.refresh();
  };
  const doMore = (newMore?: string): void => {
    if (!is(newMore)) {
      if (more === 'more') {
        newMore = 'less';
      } else {
        newMore = 'more';
      }
    }
    setAiosNodeUi(node, { more: newMore });
    AppState.current.refresh();
  };
  const doExpand = (newExpand?: string): void => {
    if (!is(newExpand)) {
      if (expand === 'expand') {
        newExpand = 'collapse';
      } else {
        newExpand = 'expand';
      }
    }
    setAiosNodeUi(node, { expand: newExpand });
    AppState.current.refresh();
  };

  const { status } = getAiosNodeAction(node);
  const { type, pathEx, nodes, clones, clonei } = node;
  if (!is(pathEx)) {
    return (<div>no path</div>);
  }
  let icon = set(type as string, 'aios');
  let spin;
  let tick = false;
  let onTick;
  let path = set(assetPath, '');
  let onPath;
  let text, onPrevious, onNext;
  let onCreate, onDelete, onClone;
  let onMore;
  let bodyView;
  let footView;
  let nodesView;
  const { full, action } = pathEx;
  if (!is(path) && is(full)) {
    path = full;
  }
  if (is(action)) {
    switch (action) {
      case 'create':
      case 'update':
        if (editing === undefined) {
          editing = true;
        }
        if (disabled === undefined) {
          disabled = false;
        }
        break;
    }
  } else {
    if (disabled === undefined) {
      disabled = true;
    }
  }
  if (icon === AiosType.AiosNodeAios) {
    icon = 'aios';
  }
  const loadHead = (): void => {
    onMore = doMore;
    const loadIcon = (): void => {
      if (isWait(status)) {
        icon = 'aios';
        spin = true;
        return;
      }
      if (is(assetFileType)) {
        icon = assetFileType;
      }
      if (is(icon)) {
        icon = icon.toLowerCase();
      }
    }
    const loadDefault = (): void => {
      // onView = doView;
      // onMode = doMode;
      // if (is(child)) {
      //   onView = doView;
      //   onMode = doMode;
      // }
    }
    const loadEditor = (): void => {
      if (isWait(status)) {
        icon = 'aios';
        spin = true;
        return;
      }
      if (isFail(status)) {
        icon = 'fail';
        return;
      }
      if (!is(child)) {
        icon = 'update';
        spin = undefined;
        path = 'editing items';
        onClone = async (): Promise<void> => {
          createAiosNodeClone(node);
          AppState.current.refresh();
        }
        if (is(clones) && clonei !== undefined) {
          text = `${clonei + 1}/${clones.length}`;
          onDelete = async (): Promise<void> => {
            deleteAiosNodeClone(node);
            AppState.current.refresh();
          }
          onPrevious = async (): Promise<void> => {
            setAiosNodeCloneIndex(node, clonei - 1);
            AppState.current.refresh();
          }
          onNext = async (): Promise<void> => {
            setAiosNodeCloneIndex(node, clonei + 1);
            AppState.current.refresh();
          }
        }
        return;
      }

      if (isOk(status)) {
        icon = 'ok';
        return;
      }
      if (assetFileType === AiosFileType.Folder) {
        onCreate = async (): Promise<void> => {
          createAiosNodeChild(node);
          AppState.current.refresh();
        };
      }
      // onView = doView;
      // onMode = doMode;
      if (is(editing)) {
        onPath = (value: string): void => {
          const path = createAiosPath({ path: value });
          setAiosNodeData(node, { path });
          AppState.current.refresh();
        };
      }

      // if (is(child)) {
      //   onPath = (value: string): void => {
      //     const path = createAiosPath({ path: value });
      //     setAiosNodeData(node, { path });
      //     AppState.current.refresh();
      //   };
      //   onDelete = async (): Promise<void> => {
      //     const parent = node.parent;
      //     if (is(parent)) {
      //       deleteAiosNodeChild(parent, node);
      //     }
      //     AppState.current.refresh();
      //   };
      // }

    }
    const loadAction = (): void => {
      const loadRemove = (): void => {
        if (!is(child)) {
          icon = 'close';
          spin = undefined;
          path = 'removing items';
          return;
        }
        if (isOk(status)) {
          icon = 'ok';
          return;
        }
        if (isWait(status)) {
          icon = 'aios';
          spin = true;
          return;
        }
        if (isFail(status)) {
          icon = 'fail';
          return;
        }
        onTick = (value: boolean): void => {
          const setTick = (n: AiosNode): void => {
            n.ui.selected = value;
            if (is(value) && is(n.nodes)) {
              n.nodes.forEach((nn: AiosNode) => { setTick(nn) })
            }
          }
          setTick(node);
          AppState.current.refresh();
        };
        if (is(node.ui.selected)) {
          tick = true;
        }
      }
      switch (action) {
        case 'create':
        case 'update':
          loadEditor();
          break;
        case 'delete':
          loadRemove();
          break;
      }
    }
    loadIcon();
    if (!is(action)) {
      if (!is(editing)) {
        loadDefault();
        return;
      }
      loadEditor();
      return;
    }
    loadAction();
  }
  const loadBody = (): void => {
    if (action === 'delete') {
      return;
    }
    if (more !== 'less') {
      return;
    }
    if (!is(action) || is(child)) {
      bodyView = (
        <AppDataBody
          node={node}
          view={view}
          onView={onView}
          mode={mode}
          onMode={onMode}
          more={more}
          expand={expand}
          disabled={disabled}
        />
      );
    }
  }
  const loadFoot = (): void => {
    if (action === 'delete') {
      return;
    }
    if (more !== 'less') {
      return;
    }
    footView = (
      <AppDataNodeFoot
        node={node}
        view={view}
        onView={onView}
        mode={mode}
        onMode={onMode}
        expand={expand}
        onExpand={doExpand}
      />);
  }
  loadHead();
  loadBody();
  loadFoot();
  const headView = (
    <AppDataNodeHead
      node={node}
      icon={icon}
      spin={spin}
      tick={tick}
      onTick={onTick}
      path={path}
      onPath={onPath}
      onDelete={onDelete}
      onPrevious={onPrevious}
      text={text}
      onNext={onNext}
      onClone={onClone}
      onCreate={onCreate}
      more={more}
      onMore={onMore}
    />);
  if (!is(child) && is(nodes)) {
    nodesView = [];
    for (let i = 0; i < nodes.length; i++) {
      const childNode = nodes[i];
      nodesView.push(<AppDataNode key={`${i}-${childNode.path}`} node={childNode} child editing={editing} disabled={disabled} />);
    }
  }
  if (!is(onClone)) {
    return (
      <>
        <Grid10 middle>
          {headView}
          {bodyView}
          {footView}
        </Grid10>
        {nodesView}
      </>
    );
  }
  return (
    <>
      <Grid10>
        {headView}
        {bodyView}
        {footView}
      </Grid10>
      {nodesView}
    </>
  );
};
