import { isFail, AiosStatus, is, isWait } from 'aios';
import { type AiosNode } from 'app/AiosNode';
import { getAiosNodeAction, setAiosNodeAction } from 'app/AiosNode/AiosNodeApi';
import { removeNodeLast } from './removeNodeLast';

export async function removeNode(node: AiosNode): Promise<void> {
    const { status, progress } = getAiosNodeAction(node);
    if (isFail(status) || !isWait(status)) {
        return;
    }
    if (!is(progress) || progress < 1.0) {
        const remNode = node.nodes?.[0];
        if (!is(remNode)) {
            setAiosNodeAction(node, { text: 'removing last', status: AiosStatus.Fail, progress: undefined });
            return;
        }
        if (!is(progress) || progress < 0.6) {
            setAiosNodeAction(node, { text: 'removing last', status: AiosStatus.Processing, progress: 0.6 });
        } else {
            if (progress < 0.7) {
                setAiosNodeAction(node, { text: 'removing last', status: AiosStatus.Processing, progress: 0.7 });
            } else {
                setAiosNodeAction(node, { text: 'removing last', status: AiosStatus.Processing, progress: 0.6 });
            }
        }
        const removed = await removeNodeLast(remNode);
        if (removed === undefined) {
            return;
        }
        if (!is(removed)) {
            setAiosNodeAction(node, { text: 'removing nodes', status: AiosStatus.Fail, progress: undefined });
            return;
        }
    }
    setAiosNodeAction(node, { text: 'removing', status: AiosStatus.Ok, progress: undefined });
}