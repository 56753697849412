import { AiosStatus } from './AiosStatus';
import { type AiosException } from './AiosException';
import { type AiosObject } from './AiosObject';
import { append, is, set } from '../functions';

export interface AiosOutput {
  text: string;
  time: number;
  size: number;
  cost: number;
  status: AiosStatus;
  outputs?: Array<Partial<AiosOutput>>;
  progress?: number;
  exception?: AiosException;

  actionId?: string;

  setOk: (options?: Partial<AiosOutput>) => AiosOutput;
  setFail: (options?: Partial<AiosOutput>) => AiosOutput;
  setException: (exception?: AiosException) => AiosOutput;
  append: (result: AiosOutput) => AiosOutput;
  override: (result: AiosOutput) => AiosOutput;
  format: () => AiosObject;
}

export function createAiosOutput(options?: Partial<AiosOutput>): AiosOutput {
  const result: AiosOutput = {
    text: set(options?.text, ''),
    time: set(options?.time, 0),
    size: set(options?.size, 0),
    cost: set(options?.cost, 0),
    status: set(options?.status, AiosStatus.None),
    outputs: set(options?.outputs, undefined),
    progress: set(options?.progress, undefined),
    exception: set(options?.exception, undefined),
    actionId: set(options?.actionId, undefined),
    setOk: set(
      options?.setOk,
      function (this: AiosOutput, values?: Partial<AiosOutput>): AiosOutput {
        this.status = set(values?.status, AiosStatus.Ok);
        this.progress = set(values?.progress, 1);
        this.time = set(values?.time, this.time);
        this.size = set(values?.size, this.size);
        this.cost = set(values?.cost, this.cost);
        return this;
      },
    ),
    setFail: set(
      options?.setFail,
      function (this: AiosOutput, values?: Partial<AiosOutput>): AiosOutput {
        this.status = set(values?.status, AiosStatus.Fail);
        this.text = set(values?.text, result.text);
        this.progress = set(values?.progress, undefined);
        this.time = set(options?.time, this.time);
        this.size = set(options?.size, this.size);
        this.cost = set(options?.cost, this.cost);
        return this.setException(values?.exception);
      },
    ),
    setException: set(
      options?.setException,
      function (this: AiosOutput, exception?: AiosException): AiosOutput {
        if (is(exception)) {
          this.status = AiosStatus.Fail;
          if (is(exception.name)) {
            if (exception.name === 'AbortError') {
              this.status = AiosStatus.FailCancel;
            }
            this.text = `${this.text} ${exception.name}`;
          }
          if (is(exception.message)) {
            this.text = `${this.text} ${exception.message}`;
          }
          if (is(exception.errors)) {
            exception.errors.forEach(
              (er: { message?: string; errorType?: string }) => {
                this.text = `${this.text} ${set(er.message, '')}`;
              },
            );
          }
          this.progress = undefined;
        }
        return this;
      },
    ),
    append: set(
      options?.append,
      function (this: AiosOutput, values: Partial<AiosOutput>): AiosOutput {
        if (is(values.text) && !is(this.text)) {
          this.text = values.text;
        }
        this.outputs = append(this.outputs, { ...values });
        const { time, size, cost } = values;
        if (is(time)) {
          this.time += time;
        }
        if (is(size)) {
          this.size += size;
        }
        if (is(cost)) {
          this.cost += cost;
        }
        return this.setException(values.exception);
      },
    ),
    override: set(
      options?.override,
      function (this: AiosOutput, values: AiosOutput): AiosOutput {
        this.append(values);
        this.status = set(values?.status, result.status);
        return this;
      },
    ),
    format: set(options?.format, function (this: AiosOutput) {
      const format: AiosObject = {
        title: `${this.text}...${this.status}`,
      };
      if (is(this.text)) {
        format.message = this.text;
      }
      if (is(this.outputs)) {
        const results: AiosObject[] = [];
        for (const result of this.outputs) {
          if (is(result.format)) {
            const child = result.format();
            results.push(child);
          }
        }
        format.results = results;
      }
      return format;
    }),
  };
  return result.setException(options?.exception);
}
