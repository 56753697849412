import {
  type AiosAction,
  type AiosAsset,
  AiosDataCommand,
  append,
  createAiosPath,
  is,
  isOk,
} from 'aios';
import { createAiosNode, findAiosNode, type AiosNode } from '../../AiosNode';
import { processNodeData, setAiosNodeAction } from 'app/AiosNode/AiosNodeApi';
import { AppState } from 'signals/AppState/AppState';
import { createAppCache } from 'utl';

export async function saveNodeData(node: AiosNode): Promise<AiosAction> {
  const action = await processNodeData(node, AiosDataCommand.Save);
  setAiosNodeAction(node, { action });
  if (!isOk(action.output?.status)) {
    return action;
  }
  const thisPath = node.pathEx.path as string;
  const cache = createAppCache();
  await cache.set(thisPath, JSON.stringify(action.output));  
  if (!window.location.href.endsWith('create')) {
    return action;
  }
  const asset = node?.item as AiosAsset;
  if (!is(asset) || !is(asset.path)) {
    return action;
  }
  const path = createAiosPath({ path: asset.path });
  let parentPath = asset.path;
  if (!is(parentPath) || !parentPath.includes('/')) {
    return action;
  }
  const count = parentPath.lastIndexOf('/');
  parentPath = parentPath.substring(0, count);
  const parent = findAiosNode(AppState.current.root, createAiosPath({ path: parentPath }));
  if (!is(parent)) {
    return action;
  }
  const newNode = createAiosNode({
    parent,
    type: node.type,
    pathEx: path,
    item: node.item,
    api: { loaded: true }
  });
  parent.nodes = append(parent.nodes, newNode);
  return action;
}
