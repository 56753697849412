import { getDateId, set } from '..';

export interface AiosData {
  accountId?: string;
  id?: string;
  date?: string;
  path?: string;
  parent?: string;
  child?: string;
  text?: string;
  json?: string;
}

export function createAiosData(options?: Partial<AiosData>): AiosData {
  const data = {
    accountId: set(options?.accountId, ''),
    id: set(options?.id, undefined),
    date: set(options?.date, getDateId()),
    path: set(options?.path, ''),
    parent: set(options?.parent, undefined),
    child: set(options?.child, undefined),
    text: set(options?.text, undefined),
    json: set(options?.json, undefined),
  };
  return data;
}
