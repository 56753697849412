function pad100(value: number): string {
  if (value < 10) {
    return `00${value}`;
  }
  if (value < 100) {
    return `0${value}`;
  }
  return `${value}`;
}

function pad10(value: number): string {
  if (value < 10) {
    return `0${value}`;
  }
  return `${value}`;
}

export function getDateId(date?: Date): string {
  if (!(date instanceof Date) || isNaN(date.getTime())) {
    date = new Date();
  }
  const year = date.getFullYear();
  const month = pad10(date.getMonth() + 1);
  const day = pad10(date.getDate());
  const hours = pad10(date.getHours());
  const minutes = pad10(date.getMinutes());
  const seconds = pad10(date.getSeconds());
  const milliseconds = pad100(date.getMilliseconds());
  return `${year}${month}${day}${hours}${minutes}${seconds}${milliseconds}`;
}

export default getDateId;
