import { AiosFileType, is, isFolder } from 'aios';
import { Grid10Row, Grid10CellButton, Grid10Cell } from 'components';
import { type AppDataProps, type AppDataType } from './AppDataType';
import { getAiosNodeData } from 'app/AiosNode/AiosNodeData';

export const AppDataNodeFoot: AppDataType = ({ node, view, onView, mode, onMode, expand, onExpand }: AppDataProps) => {
  const { assetFileType } = getAiosNodeData(node);
  // const diff = is(node.store);
  const leftButtons = [];
  const centreButtons = [];
  // const rightButtons = [];
  if (is(view) && is(onView)) {
    leftButtons.push(<Grid10CellButton
      key={'show-data'}
      tip={'data'}
      icon='data'
      onClick={async () => { is(onView) && onView('data') }}
      selected={view === 'data'}
      space
    />
    );
    if (isFolder(assetFileType)) {
      leftButtons.push(<Grid10Cell key='lb-1' space>&nbsp;</Grid10Cell>);
    } else {
      leftButtons.push(
        <Grid10CellButton
          key={'show-file'}
          tip={'file'}
          icon='file'
          onClick={async () => { is(onView) && onView('file') }}
          selected={view === 'file'}
          space
        />
      );
    }
    leftButtons.push(<Grid10Cell key='lb-2' space>&nbsp;</Grid10Cell>);
  }
  if (is(mode) && is(onMode)) {
    centreButtons.push(<Grid10Cell key='cb-1' space>&nbsp;</Grid10Cell>);
    if (view !== 'file') {
      centreButtons.push(
        <Grid10CellButton
          key={'show-form'}
          tip={'form'}
          icon='form'
          onClick={async () => { is(onMode) && onMode('form') }}
          selected={mode === 'form'}
          space
        />
      );
    } else {
      if (assetFileType === AiosFileType.Markdown) {
        centreButtons.push(<Grid10CellButton
          key={'show-markdown'}
          tip={'markdown'}
          icon='markdown'
          onClick={async () => { is(onMode) && onMode('markdown') }}
          selected={mode === 'markdown'}
          space
        />);
      }
    }
    centreButtons.push(
      <Grid10CellButton
        key={'show-code'}
        tip={'code'}
        icon='code'
        onClick={async () => { is(onMode) && onMode('code') }}
        selected={mode === 'code'}
        space
      />
    );

    for (let i = 0; i < 4; i++){
      if (centreButtons.length <= i){
        centreButtons.push(<Grid10Cell key={`cb-${i}`} space>&nbsp;</Grid10Cell>);
      }
    }

    // centreButtons.push(
    //   <Grid10CellButton
    //     key={'show-diff'}
    //     tip={'diff'}
    //     icon='diff'
    //     onClick={async () => { is(onMode) && onMode('diff') }}
    //     selected={mode === 'diff'}
    //     space
    //   />
    // );



    // if (view === 'data') {
    //   if (mode === 'form') {
    //     centreButtons.push(
    //       <Grid10CellButton
    //         key={'show-code'}
    //         tip={'show code'}
    //         icon='form'
    //         onClick={async () => { is(onMode) && onMode('code') }}
    //         space
    //       />
    //     );
    //   }
    //   if (!is(diff)) {
    //     if (mode === 'code') {
    //       centreButtons.push(
    //         <Grid10CellButton
    //           key={'show-form'}
    //           tip={'show form'}
    //           icon='code'
    //           onClick={async () => { is(onMode) && onMode('form') }}
    //           space
    //         />
    //       );
    //     }
    //     // return <Grid10Cell>&nbsp;</Grid10Cell>;
    //   }
    //   if (mode === 'code') {
    //     centreButtons.push(
    //       <Grid10CellButton
    //         key={'show-diff'}
    //         tip={'show diff'}
    //         icon='code'
    //         onClick={async () => { is(onMode) && onMode('diff') }}
    //         space
    //       />
    //     );
    //   }
    //   if (mode === 'diff') {
    //     centreButtons.push(
    //       <Grid10CellButton
    //         key={'show-form'}
    //         tip={'show form'}
    //         icon='diff'
    //         onClick={async () => { is(onMode) && onMode('form') }}
    //         space
    //       />
    //     );
    //   }
    // }
    // if (view === 'file') {
    //   if (is(diff)) {
    //     if (mode === 'code') {
    //       centreButtons.push(
    //         <Grid10CellButton
    //           key={'show-diff'}
    //           tip={'show diff'}
    //           icon='code'
    //           onClick={async () => { is(onMode) && onMode('diff') }}
    //           space
    //         />
    //       );
    //     }
    //     if (mode === 'diff') {
    //       centreButtons.push(
    //         <Grid10CellButton
    //           key={'show-code'}
    //           tip={'show code'}
    //           icon='diff'
    //           onClick={async () => { is(onMode) && onMode('code') }}
    //           space
    //         />
    //       );
    //     }
    //   }
    // }
  }
  // rightButtons.push(<Grid10Cell key='rb-2' space>&nbsp;</Grid10Cell>);
  // rightButtons.push(<Grid10Cell key='rb-2' space>&nbsp;</Grid10Cell>);
  // rightButtons.push(<Grid10CellButton key='foot-expand' tip={expand} icon={expand} onClick={async () => { is(onExpand) && onExpand() }} />);
  return (
    <Grid10Row>
      {leftButtons}
      {centreButtons}
      <Grid10Cell space>&nbsp;</Grid10Cell>
      <Grid10Cell space>&nbsp;</Grid10Cell>
      <Grid10CellButton key='foot-expand' tip={expand} icon={expand} onClick={async () => { is(onExpand) && onExpand() }} space />

      {/* {rightButtons} */}
      {/* <Grid10Cell key='rb-1' space>&nbsp;</Grid10Cell>
      <Grid10Cell key='rb-2' space>&nbsp;</Grid10Cell>
      <Grid10CellButton tip={expand} icon={expand} onClick={async () => { is(onExpand) && is(expand) && await onExpand(expand) }} space /> */}
    </Grid10Row>
  );
};
