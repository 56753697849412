import Editor, { DiffEditor } from '@monaco-editor/react';
import { AiosFileType, is } from 'aios';
import { type AiosNode } from 'app';
import { getAiosNodeData } from 'app/AiosNode/AiosNodeData';
import { ProgressBar } from 'components';
import { useApp } from 'signals';

export interface AppDataCodeProps {
  node: AiosNode;
  type?: string;
  mode?: string;
  diff?: string | undefined;
  value: string;
  onValue?: (value?: string) => void;
  disabled?: boolean;
}

export const AppDataCode: (props: AppDataCodeProps) => JSX.Element = ({ node, type, mode, diff, value, onValue, disabled }: AppDataCodeProps) => {
  const { settings } = useApp();
  const theme = !is(settings.dark) ? 'vs-dark' : undefined;
  const onChange = (v: string | undefined): void => {
    if (is(onValue)) {
      onValue(v);
    }
  };
  if (!is(type)) {
    const { assetFileType } = getAiosNodeData(node);
    switch (assetFileType) {
      case AiosFileType.Markdown:
        type = 'markdown';
        break;
      default:
        type = 'typescript';
        break;
    }

  }
  if (mode === 'diff') {
    // eslint-disable-next-line
    const onMount = (editor: { getModifiedEditor: () => any; }): void => {
      const modifiedEditor = editor.getModifiedEditor();
      modifiedEditor.onDidChangeModelContent(() => {
        const newValue = modifiedEditor.getValue();
        onChange(newValue);
      });
    };
    return (
      <DiffEditor
        height='100%'
        language={type}
        original={diff}
        modified={value}
        theme={theme}
        loading={<ProgressBar />}
        onMount={onMount}
        options={{
          readOnly: disabled
        }}
      />
    )
  }
  return (
    <Editor
      height='100%'
      language={type}
      value={value}
      theme={theme}
      loading={<ProgressBar />}
      onChange={onChange}
      options={{
        readOnly: disabled
      }}
    />
  );
};
