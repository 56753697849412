import { type AiosAccount, type AiosAction, type AiosAsset, AiosDataCommand, AiosType, is, isOk } from 'aios';
import { processNodeData, setAiosNodeAction } from 'app/AiosNode/AiosNodeApi';
import { type AiosNode } from '../../AiosNode';
import { remAiosNodeCache, setAiosNodeCache } from 'app/AiosNode/AiosNodeCache';

export async function removeNodeData(node: AiosNode): Promise<AiosAction> {
  const action = await processNodeData(node, AiosDataCommand.Remove);
  setAiosNodeAction(node, { action });
  if (isOk(action.output?.status)) {
    await remAiosNodeCache(node);    
  }
    // addAiosNodeAction(node, action);

  const parent = node.parent as AiosNode;
  if (!is(parent)) {
    return action;
  }
  const thisFull = node.pathEx.full as string;
  switch (parent.type) {
    case AiosType.Account:
      {
        const account = parent.item as AiosAccount;
        if (is(account.assets)) {
          account.assets = account.assets.filter((a) => a.path !== thisFull);
        }
      }
      break;
    case AiosType.Asset:
      {
        const asset = parent.item as AiosAsset;
        if (is(asset.assets)) {
          asset.assets = asset.assets.filter((a) => a.path !== thisFull);
        }
      }
      break;
  }
  await setAiosNodeCache(parent);

  return action;


}
