import { type AiosNode, loadNode } from 'app';
import { is, isFail } from 'aios';
import { getAiosNodeAction } from 'app/AiosNode/AiosNodeApi';

export async function loadNodeNext(node: AiosNode): Promise<boolean | undefined> {
  const { status, loaded } = getAiosNodeAction(node);
  if (isFail(status)) {
    return false;
  }
  if (!is(loaded)) {
    // if (!isWait(status)) {
    await loadNode(node);
    // }
    return undefined;
  }
  const { nodes } = node;
  if (is(nodes)) {
    try {
      // // eslint-disable-next-line @typescript-eslint/promise-function-async
      // const promises = nodes.map((child) => loadNodeNext(child));
      // await Promise.all(promises);
      // nodes.forEach(child => {
      let loaded: boolean | undefined = true;
      for (let i = 0; i < nodes.length; i++) {
        const child = nodes[i];
        if (is(child.pathEx.action)) {
          continue;
        }
        const childLoaded = await loadNodeNext(child);
        if (childLoaded === false) {
          return false;
        }
        if (childLoaded === undefined) {
          loaded = undefined;
        }
        // const { status: childStatus } = getAiosNodeAction(child);
        // if (isFail(childStatus)) {
        //   return false;
        // }
        // if (isWait(childStatus)) {
        //   return undefined;
        // }
      }
      return loaded;
      // });
    } catch (exception) {
      // output.setException(exception as AiosException);
    }
  }
  return true;
}