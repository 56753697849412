import { is, AiosStatus, isFail } from 'aios';
import { type AiosNode } from '../../AiosNode';
import { getAiosNodeAction, setAiosNodeAction } from 'app/AiosNode/AiosNodeApi';
import { removeNodeData } from './removeNodeData';

export async function removeNodeLast(node: AiosNode): Promise<boolean | undefined> {
  const { progress } = getAiosNodeAction(node);
  if (!is(progress) || progress < 0.1) {
    setAiosNodeAction(node, { text: 'removing last', status: AiosStatus.Processing, progress: 0.1 });
    return undefined;
  }
  if (progress < 0.4) {
    if (progress < 0.3) {
      setAiosNodeAction(node, { text: 'removing nodes', status: AiosStatus.Processing, progress: 0.3 });
    } else {
      setAiosNodeAction(node, { text: 'removing nodes', status: AiosStatus.Processing, progress: 0.2 });
    }
    const { nodes } = node;
    if (is(nodes)) {
      let anyUndefined = false;
      for (let i = 0; i < nodes.length; i++) {
        const child = nodes[i];
        const childRemoved = await removeNodeLast(child);
        if (childRemoved === false) {
          return false;
        }
        if (childRemoved === undefined) {
          anyUndefined = true;
        }
      }
      if (is(anyUndefined)) {
        return undefined;
      }
    }
    setAiosNodeAction(node, { text: 'removing last data', status: AiosStatus.Processing, progress: 0.5 });
    return undefined;
  }
  setAiosNodeAction(node, { text: 'removing last data', status: AiosStatus.Processing, progress: 0.9 });

  // if (!is(node.ui.selected)) {
  //   setAiosNodeAction(node, { text: 'removing last data', status: AiosStatus.Ok, progress: undefined });
  //  return true;
  // }

  await removeNodeData(node);
  const { status } = getAiosNodeAction(node);
  if (isFail(status)) {
    setAiosNodeAction(node, { text: 'removing last data', status: AiosStatus.Fail, progress: undefined });
    return false;
  }
  setAiosNodeAction(node, { text: 'removing last data', status: AiosStatus.Ok, progress: undefined });
  // const removedNode = findAiosNode(AppState.current.root, node.pathEx);
  // if (is(removedNode)) {
  //   const parent = removedNode.parent;
  //   if (is(parent)) {
  //     parent.nodes = remove(parent.nodes, removedNode);
  //   }
  // }
  return true;
}
