import { is } from 'aios';
import { Grid10CellIcon, Grid10Row, Grid10CellButton, Grid10CellInput, Grid10CellText, Grid10CellTick } from 'components';
import { type AppDataProps, type AppDataType } from './AppDataType';

export const AppDataNodeHead: AppDataType = ({
  icon, spin, tick, onTick, path, onPath, onClone, onCreate, onDelete, onPrevious, text, onNext, more, onMore
}: AppDataProps) => {
  const space = more === 'more';
  const buttons = [];
  if (is(onDelete)) {
    buttons.push(<Grid10CellButton key='head-delete' tip='delete clone' icon='delete' onClick={onDelete} space={space} />);
  }
  if (is(onPrevious)) {
    buttons.push(<Grid10CellButton key='head-previous' tip='previous clone' icon='left' onClick={onPrevious} space={space} />);
  }
  if (is(text)) {
    buttons.push(<Grid10CellText key='head-text' center space={space} >{text}</Grid10CellText>);
  }
  if (is(onNext)) {
    buttons.push(<Grid10CellButton key='head-next' tip='next clone' icon='right' onClick={onNext} space={space} />);
  }
  if (is(onClone)) {
    buttons.push(<Grid10CellButton key='head-clone' tip='create clone' icon='clone' onClick={onClone} space={space} />);
  }
  if (is(onCreate)) {
    buttons.push(<Grid10CellButton key='head-create' tip='create child' icon='create' onClick={onCreate} space={space} />);
  }
  if (is(onMore) && is(more)) {
    buttons.push(<Grid10CellButton key='head-more' tip={more} icon={more} onClick={async () => { onMore() }} space={space} />);
  }
  const span = 9 - buttons.length;
  return (
    <Grid10Row>
      {!is(onTick) ? (
        <Grid10CellIcon icon={icon} spin={spin} space={space} />
      ) : (
        <Grid10CellTick value={tick} onChange={onTick} space={space} />
      )}

      {!is(onPath) ? (
        <Grid10CellText spanx={span} space={space}>{path}</Grid10CellText>
      ) : (
        <Grid10CellInput spanx={span} value={path} onChange={onPath} space={space} />
      )}
      {buttons}
    </Grid10Row>
  );
};
